import {LOCATION} from '../actions/_actionTypes';
import initialState from '../store/_initialState';
import {removeLocationData, updateLocationData} from '../services/User.Service';

export const locationReducer = (state = initialState.currentLocation, action) => {
    switch (action.type) {
        case LOCATION.UPDATE_USER_LOCATION:
            const address = {
                address: action.payload ? action.payload.address : undefined,
                city: action.payload ? action.payload.city : undefined,
                state: action.payload ? action.payload.state : undefined,
                lat: action.payload ? action.payload.lat : undefined,
                log: action.payload ? action.payload.log : undefined,
                zipCode: action.payload ? action.payload.zipCode : undefined
            };
            updateLocationData(address);
            return Object.assign({}, state, {address});
        case LOCATION.REMOVE_USER_LOCATION:
            removeLocationData();
            return Object.assign({}, state, {
                address: {
                    address: undefined,
                    city: undefined,
                    lat: undefined,
                    log: undefined,
                    zipCode: undefined
                }
            });
        case LOCATION.IS_ADDRESS_SET:
    
            return Object.assign({}, state, {isAddressSet: action.payload});
        default:
            return state;
    }
};

