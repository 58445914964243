// Card Icons
import VISA from "../img/payment/VISA.png";
import AMERICANEXPRESS from "../img/payment/AMERICANEXPRESS.png";
import DINERSCLUB from "../img/payment/DINERSCLUB.png";
import DISCOVER from "../img/payment/DISCOVER.png";
import JCB from "../img/payment/JCB.png";
import MASTERCARD from "../img/payment/MASTERCARD.png";
import VISA_ELECTRON from "../img/payment/VISA_ELECTRON.png";
import MAESTRO from "../img/payment/MAESTRO.png";
import CHINA_UNIONPAY from "../img/payment/CHINA_UNIONPAY.png";
import DefaultCreditCvv from "../img/default-credit-card-cvv.png";

export const cardIcons = {
  "Visa": VISA,
  "Amex": AMERICANEXPRESS,
  "electron": VISA_ELECTRON,
  "MasterCard": MASTERCARD,
  "Maestro": MAESTRO,
  "JcCB": JCB,
  "Discover": DISCOVER,
  "Diners": DINERSCLUB,
  "UnionPay": CHINA_UNIONPAY,
  "unknown": DefaultCreditCvv
};

export const validServerTypes = {
  "visa": "Visa",
  "amex": "American Express",
  "Visa Electron": "Visa Electron",
  "mastercard": "MasterCard",
  "maestro": "Maestro",
  "jcb": "JCB",
  "discover": "Discover Card",
  "diners": "Diners Club",
  "unionPay": "China UnionPay",
  "unknown": "Default"
};