import * as Http from "./Http.Client";

export const addNew = card => {
  return Http.Put('api/v1/user/creditcard', {}, card, true)
    .then(result => result ? result : false);
};

export const makeDefault = id => {
  return Http.Post('api/v1/users/makes/defaults', {}, {id}, true)
    .then(result => result ? result : false);
};

export const getList = () => {
  return Http.Get('api/v1/user/creditcards', {}, true)
    .then(result => result ? result : false);
};

export const deleteCard = id => {
  return Http.Delete(`api/v1/user/${id}/creditcard`, {}, {}, true)
    .then(result => result ? result : false);
};
