import * as UserAPI from '../api/User.API';
import Config from 'Config';
import moment from 'moment';
import { addressFormatter, getErrorsFromResponse, messageType, showMessage } from "./Utilities";
import { getAddressFromLatLng } from "../components/HelpersComponents/MapComponents/Map.Component";
import { v4 as uuidv4 } from 'uuid';

//TODO remove if not used
let deviceId = uuidv4()
export const singIn = (model = {}) => {
    model = Object.assign({
        grant_type: "password",
        client_id: Config.ClientId,
        client_secret: Config.ClientSecret,
        DeviceId: deviceId
    }, model);
    return UserAPI.signIn(model);
};

export const getCurrentUser = () => {
    return UserAPI.getCurrentUser().then(result => {
        if (result) {
            if (result.success) {
                updateAuthData(result.data);
                return result.data;
            }
        }
        return false
    })
};

// Local Storage

/*
 *  START Authentication data
 */

export const getAuthData = () => {
    if (!localStorage.getItem("AuthData")) {
        localStorage.setItem('AuthData',
            JSON.stringify({
                DeviceId: deviceId,
            }));
    }
    const authData = localStorage.getItem("AuthData");
    if (!authData) return false;
    return JSON.parse(authData);
};

export const setAuthData = authData => {
    localStorage.setItem('AuthData',
        JSON.stringify({
            token: authData.access_token,
            refreshToken: authData.refresh_token,
            expiresIn: authData.expires_in,
            DeviceId: deviceId,
        }));
    return authData;
};

export const updateAuthData = user => {
    const AuthData = getAuthData();
    if (AuthData && user) {
        const UpdatedAuthData = Object.assign(AuthData, {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            clientFullName: user.clientFullName,
            email: user.email,
            phone: user.phone,
            restId: user.restaurant ? user.restaurant.id : false,
            username: user.username,
            ordersCount: user.ordersCount,
            reviewsCount: user.reviewsCount,
            active: user.active,
        },
            user.roles && {
                roles: user.roles.join(','),
            });
        const Types = {
            categories: user.preferredCategory,
            dietFriendly: user.preferredDietFriendly,
            kitchenType: user.preferredKitchenType
        }
        localStorage.setItem(
            "AuthData",
            JSON.stringify(UpdatedAuthData)
        );
        localStorage.setItem(
            "Types",
            JSON.stringify(Types)
        );
        return UpdatedAuthData;
    }
    return false;
};

export const removeAuthData = () => {
    localStorage.removeItem("AuthData");
    return true;
};

export const getAccessToken = () => {
    const authData = getAuthData();
    if (!authData) return false;
    const token = `${JSON.parse(authData).name}`;
    if (!token) return false;
    return token;
};
/*
 *  END Authentication data
 */

/*
 *  START User current location
 */
export const getUserLocation = (storeData = false) => {
    const geolocation = navigator.geolocation;

    return new Promise((resolve, reject) => {
        if (!geolocation) {
            reject(new Error('Not Supported'));
        }
        const locationData = getStoredUserLocation();
        if (locationData && moment.unix(locationData.expDate).isSameOrAfter(moment())) {
            resolve(locationData.address);
        } else {
            geolocation.getCurrentPosition(position => {
                if (position.coords) {
                    getAddressFromLatLng(position.coords.latitude, position.coords.longitude).then(res => {
                        const address = addressFormatter(res);
                        if (storeData) {
                            updateLocationData({
                                address: address,
                                expDate: moment().add(5, 'minutes').unix()
                            });
                        }
                        resolve(address);
                    })

                } else {
                    reject(new Error('Invalid position object.'))
                }
            }, (err) => {
                showMessage(messageType.warning, 'You need to allow to get location')
            });

        }
    });
};

export const getStoredUserLocation = () => {
    const locationData = localStorage.getItem("LocationData");
    if (!locationData) return false;
    return JSON.parse(locationData);
};

export const updateLocationData = address => {
    removeLocationData();
    localStorage.setItem(
        "LocationData",
        JSON.stringify(address)
    );
};

export const removeLocationData = () => {
    localStorage.removeItem("LocationData");
    return true;
};

/**
 * Forgot password
 */
export const requestVerificationCode = email => {
    const formData = new FormData();
    formData.append('email', email);

    return UserAPI.requestVerificationCode(email).then(result => {

        if (result && result.success) {
            showMessage(messageType.success, getErrorsFromResponse(result), 10);
            return result.data;
        }
        showMessage(messageType.warning, getErrorsFromResponse(result), 5);
        return false;
    })
};

export const forgotPassword = (model = {}) => {
    return UserAPI.forgotPassword(model).then(result => {
        if (result && result.success) {
            showMessage(messageType.success, getErrorsFromResponse(result), 10);
            return result.data;
        }
        showMessage(messageType.warning, getErrorsFromResponse(result), 5);
        return false;
    })
};

/**
 * User Firebase notification
 */

export const sendFBTokenToServer = token => {
    const formData = new FormData();
    formData.append('token', token);

    return UserAPI.sendFBTokenToServer(formData).then(result => {
        if (result && result.success) {
            return true
        }
        return false
    })
};

