import * as Http from "./Http.Client";

export const addClientAddress = data =>
    Http.Put(`api/v1/client/address`, {}, data,true)
    .then(result => result ? result : false);

export const getClientAddress = () =>
    Http.Get(`api/v1/client/addresses`,{}, true)
    .then(result => result ? result : false);

export const deleteClientAddress = (id) =>
    Http.Delete(`api/v1/client/address/${id}`,{},{}, true)
    .then(result => result ? result : false);

export const changeClientAddress = (id,data) =>
    Http.Post(`api/v1/client/address/${id}`,{},data, true)
    .then(result => result ? result : false);
export const editClientAddress = (id,data) =>
    Http.Post(`api/v1/client/address/${id}`,{},data, true)
    .then(result => result ? result : false);

