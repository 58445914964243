const Paths = {
	home: '/',
	cateringSection: '/catering-section',
	partnerUs: '/partner-with-us',
	delivery: '/deliver-with-meameal',
	// preview: '/',
	order: '/shopping-cart',
	account: '/account',
	// restaurant: '/restaurant',
	resetPassword: "/user/password/reset/:token",
	catering: "/catering-menu",
	cateringRestaurant: "/catering-menu/:restaurantSlug",
	shoppingCart:"/shopping-cart",
	guest:'/verify/client/account/:token',
	signIn: '/login',
	regular: '/restaurant-menu/',
	restaurant: '/restaurant-menu/:restaurantSlug',
};
export default Paths;
