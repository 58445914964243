
export const USER = {
	LOGGED_IN: 'USER_LOGGED_IN',
	LOGGED_OUT: 'USER_LOGGED_OUT',

	UPDATE_PERSONAL_INFORMATION: 'UPDATE_PERSONAL_INFORMATION'
};
export const PAGE = {
	ADD_PAGE_COUNT:'ADD_PAGE_COUNT',
	ADD_PAGE_COUNT_ONE:'ADD_PAGE_COUNT_ONE'
}

export const ORDER_TYPE_SWITCHERS = {
	PICK_UP: 'PICK_UP',
	DELIVERY: 'DELIVERY',
};
export const SET={
	WIDTH:"WIDTH"
}
export const LOADING = {
	START_LOADING: 'START_LOADING',
	STOP_LOADING: 'STOP_LOADING',

	START_LOCAL_LOADING: 'START_LOCAL_LOADING',
	STOP_LOCAL_LOADING: 'STOP_LOCAL_LOADING'
};

export const CLIENT = {
	SET_ORDERS: 'SET_ORDERS',
	PAGINATE_ORDERS: 'PAGINATE_ORDERS',

	CLEAR_ORDERS: 'CLEAR_ORDERS',
};

export const HEADER = {
	SHOW_HEADER: 'SHOW_HEADER',
	HIDE_HEADER: 'HIDE_HEADER',
};

export const BASKET = {
	SET_ITEMS: 'SET_ITEMS',
	ADD_FIRST_ITEM: 'ADD_FIRST_ITEM',
	ADD_NEW_PRODUCT: 'ADD_NEW_PRODUCT',
	UPDATE_ITEM: 'UPDATE_ITEM',
	REMOVE_ITEM: 'REMOVE_ITEM',
	CHANGE_COUNT: 'CHANGE_COUNT',
	CALCULATE_BASKET: 'CALCULATE_BASKET',
	CLEAR_CART: 'CLEAR_CART',
	REMOVE_LOCAL_ITEM: 'REMOVE_LOCAL_ITEM',
};

export const LOCATION = {
	UPDATE_USER_LOCATION: 'UPDATE_USER_LOCATION',
	REMOVE_USER_LOCATION: 'REMOVE_USER_LOCATION',
	WRITE_LATER: 'WRITE_LATER',
	IS_ADDRESS_SET: 'IS_ADDRESS_SET'
};


export const LEFT_MENU = {
	SHOW_LEFT_MENU: 'SHOW_LEFT_MENU',
	HIDE_LEFT_MENU: 'HIDE_LEFT_MENU',
};

export const CATEGORIES = {
	SET_CATEGORIES: 'SET_CATEGORIES'
};
export const ORDER_HISTORY = {
	GET_ORDERS:"GET_ORDERS",
	GET_ORDERS_PAGINATE:"GET_ORDERS_PAGINATE",
}
export const RESTAURANTS = {
	SET_RESTAURANTS: 'SET_RESTAURANTS',
	SET_SELECTED_RESTAURANT: 'SET_SELECTED_RESTAURANT',
	REMOVE_SELECTED_RESTAURANT: 'REMOVE_SELECTED_RESTAURANT',
	SET_RESTAURANT_PAGE_COUNT:'SET_RESTAURANT_PAGE_COUNT',
	PAGINATE_RESTAURANT:'PAGINATE_RESTAURANT',
	SELECTED_RESTAURANT_PRODUCT:'SELECTED_RESTAURANT_PRODUCT',
	CHANGE_RESTAURANT:'CHANGE_RESTAURANT',
	CHANGE_FAVORITE:'CHANGE_FAVORITE',
};
export const REGULAR_MENU = {
	SHOW_MODAL: 'SHOW_MODAL_REGULAR-MENU',
	SET_SELECTED_PRODUCT: 'SET_SELECTED_PRODUCT',

};

export const PRODUCTS = {
	SET_PRODUCTS: 'SET_PRODUCTS',
	PAGINATE_PRODUCTS: 'PAGINATE_PRODUCTS',
	CHANGE_LOCAL_ITEM: 'CHANGE_LOCAL_ITEM',
	SET_CHANGED_ITEM:'SET_CHANGED_ITEM'
};

export const MODAL = {
	SHOW_MODAL: 'SHOW_MODAL'
};

export const SETTINGS = {
	SET_SELECTED_SETTINGS: 'SET_SELECTED_SETTINGS'
};

export const IMG = {
	SHOW: 'SHOW',
	HIDE: 'HIDE'
};

export const REVIEW = {
	SET_REVIEWS: 'SET_REVIEWS',
	CAN_ADD_REVIEW: 'CAN_ADD_REVIEW',
	ADD_NEW_REVIEW: 'ADD_NEW_REVIEW',
	CLEAR_REVIEWS: 'CLEAR_REVIEWS'
};

export const FILTER = {
	UPDATE_FILTER: 'UPDATE_FILTER',
	RESET_FILTER: 'RESET_FILTER',
	SELECTED_RESTAURANT_FILTER:'SELECTED_RESTAURANT_FILTER',
	UPDATE_DEFAULT_DATA: 'UPDATE_DEFAULT_DATA',
	SHOW_FILTER: 'SHOW_FILTER',
};

export const ORDER_DELIVERY_TYPE = {
	UPDATE_ORDER_DELIVERY_TYPE: 'UPDATE_ORDER_DELIVERY_TYPE'
};

export const ORDER_TYPE = {
	UPDATE_ORDER_TYPE: 'UPDATE_ORDER_TYPE'
};

export const ORDER_UPDATE = {
	UPDATE_DRIVER_ORDER_PROPERTY: 'UPDATE_DRIVER_ORDER_PROPERTY',
	UPDATE_CUSTOMER_ORDER_PROPERTY: 'UPDATE_CUSTOMER_ORDER_PROPERTY'
};

export const ADDRESS_NOTE = {
	SET_ADDRESS_NOTE: 'SET_ADDRESS_NOTE'
};

export const DELIVERY_TIME = {
	UPDATE_DELIVERY_DAY: 'UPDATE_DELIVERY_DAY',
	UPDATE_DELIVERY_TIME: 'UPDATE_DELIVERY_TIME',
};

export const CreditCard_Type = {
	CARD_NUMBER_CHANGE: 'CARD_NUMBER_CHANGE',
	HOLDER_NAME_CHANGE: 'HOLDER_NAME_CHANGE',
	EXP_DATE_CHANGE: 'EXP_DATE_CHANGE',
	CVV_CHANGE: 'CVV_CHANGE',
	CARD_SELECT: 'CARD_SELECT',
	CARD_SAVE_CHECK: 'CARD_SAVE_CHECK',
	UPDATE_CARD_LIST: 'UPDATE_CARD_LIST',
	ZIP_CODE_CHANGE: 'ZIP_CODE_CHANGE',
	CLEAR_CARD: 'CLEAR_CARD'
}

export const NOTIFICATION = {
	ADD: 'ADD',
	REMOVE: 'REMOVE'
}

export const CHECKOUT_ORDER = {
	ADD_USER_LOCATION: 'ADD_USER_LOCATION',
	STEP: 'STEP',
	ADD_USER_INFO: 'ADD_USER_INFO',
	DONATE: 'DONATE',
	REMOVE_ALL_DATA: 'REMOVE_ALL_DATA',
	CHANGE_ARRIVAL_TIME:'CHANGE_ARRIVAL_TIME',
	FINALLY:'FINALLY',
}