import {
  LOADING,
  LEFT_MENU,
  CATEGORIES,
  PRODUCTS,
  RESTAURANTS,
  MODAL,
  HEADER,
  FILTER,
  REGULAR_MENU,
  SETTINGS,
  SET,
} from "./_actionTypes";

import * as HomeAPI from "../api/Home.API";
import * as RestaurantAPI from "../api/Restaurant.API";
import * as HomeService from "../services/Home.Service";

import * as BaseService from "../services/Base.Service";

export const showLoading = (state) => (dispatch) => {
  if (state) {
    dispatch({ type: LOADING.START_LOADING });
  } else {
    dispatch({ type: LOADING.STOP_LOADING });
  }
};

export const changeIsOpen = (state) => (dispatch) => {
  if (state) {
    dispatch({ type: LOADING.START_LOADING });
  } else {
    dispatch({ type: LOADING.STOP_LOADING });
  }
};
export const changeSelectedSettings = (settingsType) => (dispatch) => {
  dispatch({
    type: SETTINGS.SET_SELECTED_SETTINGS,
    payload: settingsType,
  });
};

export const showLocalLoading = (state) => (dispatch) => {
  if (state) {
    dispatch({ type: LOADING.START_LOCAL_LOADING });
  } else {
    dispatch({ type: LOADING.STOP_LOCAL_LOADING });
  }
};

export const showLeftMenu =
  (state = true) =>
  (dispatch) => {
    if (state) {
      dispatch({ type: LEFT_MENU.SHOW_LEFT_MENU });
    } else {
      dispatch({ type: LEFT_MENU.HIDE_LEFT_MENU });
    }
  };
export const setInnerWidth = (width) => (dispatch) => {
  dispatch({ type: SET.WIDTH, payload: width });
};

export const showHeader =
  (state = true) =>
  (dispatch) => {
    if (state) {
      dispatch({ type: HEADER.SHOW_HEADER });
    } else {
      dispatch({ type: HEADER.HIDE_HEADER });
    }
  };

export const getProducts =
  (offset, limit, model = {}, isDelivery, isAuth) =>
  (dispatch) => {
    dispatch({ type: LOADING.START_LOCAL_LOADING });
    return HomeService.getProducts(offset, limit, model, isAuth, isDelivery)
      .then((result) => {
        if (result && result.success) {
          const { data } = result;
          if (data) {
            dispatch({
              type:
                offset === 1
                  ? PRODUCTS.SET_PRODUCTS
                  : PRODUCTS.PAGINATE_PRODUCTS,
              payload: data,
            });
          }
        }

        return result;
      })
      .finally(() => dispatch({ type: LOADING.STOP_LOCAL_LOADING }));
  };
export const clearProductList = () => (dispatch) => {
  dispatch({
    type: PRODUCTS.SET_PRODUCTS,
    payload: [],
  });
};
export const changeLocalIsFavorites = (items) => (dispatch) => {
  dispatch({
    type: PRODUCTS.CHANGE_LOCAL_ITEM,
    payload: items,
  });
};
export const changeLocalRestaurantIsFavorites = (items) => (dispatch) => {
  dispatch({
    type: RESTAURANTS.CHANGE_RESTAURANT,
    payload: items,
  });
};
export const changeRestaurantIsFavorites = () => (dispatch) => {
  dispatch({
    type: RESTAURANTS.CHANGE_FAVORITE,
  });
};
export const setLocalItems = (items) => (dispatch) => {
  dispatch({
    type: PRODUCTS.SET_CHANGED_ITEM,
    payload: items,
  });
};
export const getCaterings =
  (offset = 1, limit = 10, model) =>
  (dispatch) =>
    HomeService.getCaterings(offset, limit, model).then((result) => {
      if (result && result.success) {
        const { data } = result;

        if (data) {
          dispatch({
            type:
              offset === 1 ? PRODUCTS.SET_PRODUCTS : PRODUCTS.PAGINATE_PRODUCTS,
            payload: data,
          });
        }
      }
    });

export const getRestaurants =
  (offset, limit, model = {}, isAuth, isDelivery) =>
  (dispatch) => {
    dispatch({ type: LOADING.START_LOCAL_LOADING });
    return RestaurantAPI.getRestaurants(
      offset,
      limit,
      model,
      isAuth,
      isDelivery
    )
      .then((result) => {
        if (result && result.success) {
          const { data } = result;
          if (data?.items?.length) {
            dispatch({
              type:
                offset === 1
                  ? RESTAURANTS.SET_RESTAURANTS
                  : RESTAURANTS.PAGINATE_RESTAURANT,
              payload: data,
            });
          }
        }
      })
      .finally(() => dispatch({ type: LOADING.STOP_LOCAL_LOADING }));
  };
export const clearRestaurantList = () => (dispatch) => {
  dispatch({
    type: RESTAURANTS.SET_RESTAURANTS,
    payload: [],
  });
};
export const getCategories =
  (isDelivery = false, empty = false) =>
  (dispatch) => {
    if (empty) {
      dispatch({
        type: CATEGORIES.SET_CATEGORIES,
        payload: [],
      });
    } else {
      dispatch({ type: LOADING.START_LOCAL_LOADING });
      return HomeAPI.getCategories(isDelivery)
        .then((result) => {
          if (result.data) {
            dispatch({
              type: CATEGORIES.SET_CATEGORIES,
              payload: result.data.categories,
            });
          }
          return result.data;
        })
        .finally(() => {
          dispatch({ type: LOADING.STOP_LOCAL_LOADING });
        });
    }
  };

export const showModal =
  (modalType, state = true, modalProps = {}) =>
  (dispatch) => {
    dispatch({
      type: MODAL.SHOW_MODAL,
      payload: { modalType, modalProps, state },
    });
  };

export const updateSelectedRestaurant =
  (restaurantSlug, isAuth) => (dispatch) => {
    if (!restaurantSlug) {
      dispatch({
        type: RESTAURANTS.SET_SELECTED_RESTAURANT,
        payload: {},
      });
    }
    dispatch({ type: LOADING.START_LOCAL_LOADING });
    return RestaurantAPI.getRestaurantDetails(restaurantSlug, isAuth).then(
      (result) => {
        if (result && result.success) {
          const { data } = result;
          dispatch({
            type: RESTAURANTS.SET_SELECTED_RESTAURANT,
            payload: data,
          });
          dispatch({ type: LOADING.STOP_LOCAL_LOADING });
        }
        return true;
      }
    );
  };
export const setLocalSelectedRestaurantProduct = (data) => (dispatch) => {
  dispatch({
    type: RESTAURANTS.SELECTED_RESTAURANT_PRODUCT,
    payload: data,
  });
};
export const selectedRestaurantProduct =
  (restaurantSlug, model) => (dispatch) => {
    if (!restaurantSlug) {
      dispatch({
        type: RESTAURANTS.SET_SELECTED_RESTAURANT,
        payload: {},
      });
    }
    dispatch({ type: LOADING.START_LOCAL_LOADING });
    return RestaurantAPI.getRestaurantProducts(restaurantSlug, model).then(
      (result) => {
        if (result && result.success) {
          const { data } = result;
          dispatch({
            type: RESTAURANTS.SELECTED_RESTAURANT_PRODUCT,
            payload: data,
          });
          dispatch({ type: LOADING.STOP_LOCAL_LOADING });
        }
      }
    );
  };

export const setSelectedProduct = (data) => (dispatch) => {
  dispatch({
    type: REGULAR_MENU.SET_SELECTED_PRODUCT,
    data,
  });
};
export const showModalRegularMenu = () => (dispatch) => {
  dispatch({
    type: REGULAR_MENU.SHOW_MODAL,
  });
};

export const updateFilterData = (isCatering) => (dispatch) => {
  return BaseService.getFilterDefaultData(isCatering).then((result) => {
    if (result) {
      dispatch({ type: FILTER.UPDATE_DEFAULT_DATA, payload: result });
    }
    return result;
  });
};
