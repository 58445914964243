import React from "react";
import PropTypes from "prop-types";
import BannerCateringFindFood from "../../img/BannerCateringFindFood.png";
import AddAddress from "../../components/HelpersComponents/Add.Address.Component";
import "../../styles/CateringFindFood/CateringFindFood.scss"
import { updateOrderDeliveryType } from "../../actions/orderActions";
import gps from "../../img/gps.png";
import gpsIcon from "../../img/gps_icon.png";
import makaron from "../../img/makaron.png";
import { connect } from "react-redux";
import { OrderDeliveryType } from "../../enums/Order.Status.Enum";
import {  updateFilterData } from "../../actions/baseActions";
import delivery from "../../img/delivery.svg"
import pickup from "../../img/pickup.svg"

const CateringFindFood = ({
    showLocation = true,
    onAddressConfirmed = f => f,
    updateOrderDeliveryType = f => f,
    isDelivery = true,
   
}) => {


    return (
        <header className="catering_menu" style={{
            "backgroundImage": `url(${BannerCateringFindFood})`,
        }} >
            <div className="HomeSearchComponent">
                <div className="get_food_type_wrapper">

                    <div className="get_food_type_desc">
                        <h2>Fastest <span>Delivery</span> & Easy <span>Pickup</span></h2>
                        <p>Best cooks and best delivery team all at your service. Hot and fresh food will reach you in 60
                            minutes.</p>
                    </div>
                    {showLocation &&
                        <div className={"getFoodType"}>
                            <div>
                                <div>
                                    <button className={isDelivery ? 'active' : ''}
                                        onClick={() => updateOrderDeliveryType(OrderDeliveryType.IS_DELIVERY)}><img
                                            src={delivery} alt="" />Delivery
                                    </button>
                                    <button className={!isDelivery ? 'active' : ''}
                                        onClick={() => updateOrderDeliveryType(OrderDeliveryType.IS_PICK_UP)}>
                                        <img src={pickup} alt="" />Pick Up
                                    </button>
                                </div>
                            </div>
                            <AddAddress onAddressConfirmed={onAddressConfirmed} />
                        </div>}

                </div>
            </div>
            <div className="info_wrapper" >
                <img src={gps} className="gps" alt="gps" />
                <img src={gpsIcon} className="gps-icon big_icon" style={{display:"none"}} alt="icon" />
                <img src={gpsIcon} className="gps-icon small_icon" style={{display:"none"}} alt="gps" />
                <div className="for_your_office" >
                    <h1>For your office </h1>
                    <h2>Simplified and customized ordering for your employee lunches</h2>
                </div>
                <div className="calls_meeting">
                    <h1>Sales calls and meetings </h1>
                    <h2>Reliable service and on time delivery to keep all your teammates satisfied. Live support and easy
                        contact.</h2>
                </div>
                <div className="budget" >
                    <h1>Control your budget</h1>
                    <h2>Choose from your favorite restaurants that suits your budget. With flexibility that is diet friendly and delicious. </h2>
                </div>
            </div>
            <div className="offer_box_wrapper">
                <img src={makaron} alt="makaron" />
                <div>
                    <h1>Find restaurants that offer boxed lunches making it easier to access and distribute. </h1>
                    <span>View More</span>
                </div>

            </div>
        </header >
    );
};

CateringFindFood.propTypes = {
    backgroundImg: PropTypes.string,
    title: PropTypes.string,
    showLocation: PropTypes.bool
};

const mapStateToProps = state => ({
    isDelivery: state.isDelivery,
    deliveryTime: state.deliveryTime,
    isCatering: state.isCatering
});

const mapDispatchToProps = {
    updateOrderDeliveryType,
    updateFilterData
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CateringFindFood);
