import React, {useEffect} from 'react';

const ModalWrapper = ({
                          showModal,
                          closeModal,
                          setData = f => f,
                          children,
                          height,
                          zIndex=999
                      }) => {
    useEffect(() => {
        if (showModal) {
            document.body.style.overflow = 'hidden';
        }
        return (() => {
            document.body.style.overflowY = 'scroll';
        })
    }, [showModal]);
    if (!showModal) return null
    return (
        <>
            <div
                style={{top: height, zIndex}}
                className='modal_fon'
                onClick={() => {
                    closeModal(false);
                    setData()
                }}></div>
            {children}
        </>
    )
}
export default ModalWrapper