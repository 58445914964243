import {combineReducers} from 'redux';
// User Reducer
import {
	userReducer,
} from './userReducer';

// Location Reducer
import {
	locationReducer
} from './locationReducer';

// Base Reducer
import {
	loadingReducer,
	localLoadingReducer,
	leftMenuReducer,
	showModalReducer,
	showHeaderReducer,
	setSelectedSettingsReducer,
	setInnerWidthReducer
} from "./baseReducer";

// Category Reducer
import {
	categoriesReducer,
	productsReducer
} from './categoriesReducer'
// restaurant
import {
	selectedRestaurantReducer,
	restaurantsReducer,
	setRestaurantPageCountReducer,
	selectedRestaurantProductReducer
} from './restaurantReducer'

// Basket Reducer
import {
	basketReducer
} from './basketReducer';

// Reviews Reducer
import {
	reviewReducer
} from './reviewReducer';


import {filterDefaultDataReducer, filterModalReducer, filterReducer, selectedRestaurantFilterReducer} from "./filterReducer";

// order type
import {orderDeliveryTypeReducer, orderTypeReducer} from './orderReducer';


import { notificationReducer } from "./notificationReducer";
import { selectedProductReducer } from './regularMenuSetingsReducer';
import { checkoutReducer } from './checkoutReducer';
import { pageCountReducer } from './pageCountReducer';
import { orderHistoryReducer } from './orderHistoryReducer';

const rootReducer = combineReducers({
	modalVisible: showModalReducer,
	leftMenuVisible: leftMenuReducer,
	user: userReducer,
	basket: basketReducer,
	loading: loadingReducer,
	localLoading: localLoadingReducer,
	currentLocation: locationReducer,
	categories: categoriesReducer,
	products: productsReducer,
	restaurants: restaurantsReducer,
	selectedRestaurant: selectedRestaurantReducer,
	reviews: reviewReducer,
	// filter
	filter: filterReducer,
	defaultFilterData: filterDefaultDataReducer,
	showHeader: showHeaderReducer,
	// client
	isDelivery: orderDeliveryTypeReducer,
	isCatering: orderTypeReducer,
	notification: notificationReducer,
	regularMenu:selectedProductReducer,
	checkout:checkoutReducer,
	selectedSettings:setSelectedSettingsReducer,
	pageCount:pageCountReducer,
	pageCountRestaurant:setRestaurantPageCountReducer,
	selectedRestaurantProduct:selectedRestaurantProductReducer,
	selectedRestaurantFilter:selectedRestaurantFilterReducer,
	orderHistory:orderHistoryReducer,
	width:setInnerWidthReducer,
	filterModal:filterModalReducer,
});

export default rootReducer;
