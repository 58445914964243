import Setting from '../../../img/Dashboard/Setting.png';
import Coupon from '../../../img/Dashboard/Coupon.png';
import creditCard from '../../../img/Dashboard/creditCard.png';
import FAQ from '../../../img/Dashboard/FAQ.png';
import Heart from '../../../img/Dashboard/Heart.png';
import mapPin from '../../../img/Dashboard/mapPin.png';
import Order from '../../../img/Dashboard/Order.png';
import Privacy from '../../../img/Dashboard/Privacy.png';
import redCoupon from '../../../img/Dashboard/redCoupon.png';
import redcreditCard from '../../../img/Dashboard/redcreditCard.png';
import redFAQ from '../../../img/Dashboard/redFAQ.png';
import redHeart from '../../../img/Dashboard/redHeart.png';
import redmapPin from '../../../img/Dashboard/redmapPin.png';
import redOrder from '../../../img/Dashboard/redOrder.png';
import redPrivacy from '../../../img/Dashboard/redPrivacy.png';
import redSetting from '../../../img/Dashboard/redSetting.png';
import redTerms from '../../../img/Dashboard/redTerms.png';
import redTricketStar from '../../../img/Dashboard/redTicketStar.png';
import SheildDone from '../../../img/Dashboard/ShieldDone.png';
import Terms from '../../../img/Dashboard/Terms.png';
import TricketStar from '../../../img/Dashboard/TicketStar.png';
export const Icon = {
    Setting, Coupon, creditCard, FAQ, Heart,
    mapPin, Order, Privacy, redCoupon, redcreditCard, redFAQ,
    redHeart, redmapPin, redOrder, redPrivacy, redSetting, redTerms, redTricketStar, SheildDone, Terms, TricketStar
}