import * as HomeAPI from '../api/Home.API';
import { StringifyUrlEncoded } from "../api/Http.Client";
import { subscribeEmail } from "../api/Home.API";
import { messageType, showMessage } from "./Utilities";

export const getProducts = (offset, limit, model, isAuth, isDelivery) => {
	const queryModel = StringifyUrlEncoded({ page: offset, limit, ...model });
	return HomeAPI.getProducts(queryModel, isDelivery, isAuth)
};

export const getCaterings = (offset = 1, limit = 10, model = {}) => {
	const queryModel = StringifyUrlEncoded({ offset, limit, ...model });
	return HomeAPI.getCaterings(queryModel)
};

export const subscribe = (email) => {
	return subscribeEmail(email).then(res => {
		if (res.success) {
			showMessage(messageType.success, 'You have successfully subscribed to the newsletter.')
			return
		}
		showMessage(messageType.warning, res.message.email)
	})
};
