import * as Http from "./Http.Client";

export const getCategories = (isDelivery) => {
	return Http.Get(`api/v1/product/categories`, {},false,isDelivery)
		.then(result => result ? result : false);
};

export const getCoupon = (data) => {
	return Http.Post(`api/v1/get/coupon`, {}, data, true, true)
		.then(result => result ? result : false);
};
export const getFavoriteItem = () => {
	return Http.Get(`api/v1/favorites`, {}, true, true)
		.then(result => result ? result : false);
};

export const getCouponPercent = (data) => {
	return Http.Post(`api/v1/coupon-percent`, {}, data, true, true)
		.then(result => result ? result : false);
};

export const getProducts = (queryModel,isDelivery,isAuth) => {
	return Http.Get(`api/v1/products/search?${queryModel}`, {}, isAuth,isDelivery)
			.then(result => result ? result : false);
};

export const getCaterings = () => {
	return Http.Get(`api/v1/catering`, {})
		.then(result => result ? result : false);
};

export const getSingleProductDetails = productSlug => {
	return Http.Get(`api/v1/get/product/${productSlug}`, {}, {}, true, true)
		.then(result => result ? result : false);
};


export const getFilterDefaultData = () => {
	return Http.Get('api/v1/product/filter/data', {})
		.then(result => result ? result : false);
};

export const subscribeEmail = (email) => {
	return Http.Post('api/v1/mains/subscribes', {}, { email })
		.then(result => result ? result : false)
}
