import { getClientOrders } from "../api/Order.API";
import { LOADING, ORDER_HISTORY } from "./_actionTypes";

export const getOrderHistoryList = (offset) => dispatch => {
    dispatch({ type: LOADING.START_LOCAL_LOADING })
    return getClientOrders(offset).then(result => {
        if (result && result.success) {
            const { data } = result;
            if (data) {
                dispatch({
                    type: offset === 1 ? ORDER_HISTORY.GET_ORDERS : ORDER_HISTORY.GET_ORDERS_PAGINATE,
                    payload: data
                })
            }
        }
        dispatch({ type: LOADING.STOP_LOCAL_LOADING })
        return result
    });
}