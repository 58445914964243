import React,{memo} from "react"

import Foto from "../../img/Foto.png"
import '../../styles/SeeDetails/SeeDetails.scss'
import WrapperButton from "../WrapperButton/WrapperButton"

const SeeDetails = memo((props) => {

    return (
        <div className={'see_details_wrapper '}>
            <div className="right_section" >
                <div className={'see_details_left'}>
                    <img className="Foto" src={Foto} alt="Foto" />
                </div>
                <WrapperButton
                    header={'Become driver'}
                    desc={'Find a right work for you'}
                    btn={'See details'}
                />
            </div>
            {/* <div className="fruits_photo">
                <img src={HreoImg} alt="HreoImg" />
            </div> */}
        </div>
    )

})
export default SeeDetails;