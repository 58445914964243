import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import icon from "../../img/extrametion.png";
const UserInfo = ({ user, step, address, userInfo, isAuth, isDelivery }) => {
  const history = useHistory();
  if (!isDelivery) {
    return false;
  }
  return (
    <div className="sp_option_wrapper">
      <p className="sp_header">Delivery to</p>
      <div className="sp_user_info">
        {step === 3 && (
          <span>
            {isAuth
              ? `${user.firstName}  ${user.lastName}`
              : `${userInfo.firstName} ${userInfo.lastName}`}
          </span>
        )}
        {address && <span>{address}</span>}
        {step === 2 && !isAuth && (
          <div className="sc_user_error_message">
            <img src={icon} alt="icon" />
            <span>
              If you want to change delivery addres, please click{" "}
              <span
                className="navigate_button"
                onClick={() => {
                  history.push("/");
                }}
              >
                here
              </span>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  user: state.user.data,
  isAuth: state.user.isAuth,
  address: state.currentLocation.address.address,
  userInfo: state.checkout.userInfo,
  step: state.checkout.checkoutStep,
  isDelivery: state.isDelivery,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
