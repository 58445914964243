export const Scroll = {
    // Scroll to position in page
    scrollToInPage(position, duration = 500){
        let start = window.scrollY,
            change = position - start,
            currentPosition = 0,
            step = 5;

        let animateScroll = function () {
            currentPosition += step;
            window.scrollTo(0, Scroll.easeInOutQuad(currentPosition, start, change, duration));
            if (currentPosition < duration) {
                setTimeout(animateScroll, step);
            }
        };
        animateScroll();
    },

    scrollToInElement(element, position, duration = 500) {
        if(element === null || element === undefined){
            return;
        }
        let start = element.scrollTop,
            change = position - start,
            currentPosition = 0,
            step = 5;

        let animateScroll = function () {
            currentPosition += step;
            element.scrollTop = Scroll.easeInOutQuad(currentPosition, start, change, duration);
            if (currentPosition < duration) {
                setTimeout(animateScroll, step);
            }
        };
        animateScroll();
    },

    scrollToEndOfElement(element, duration = 500) {
        if(element === null || element === undefined){
            return;
        }

        let lastChild = element.lastElementChild;

        if(lastChild === null || lastChild === undefined){
            return;
        }
        Scroll.scrollToInElement(
            element,
            lastChild.offsetTop,
            duration
        );
    },

    scrollToStartOfElement(element, duration = 500) {
        if(element === null || element === undefined){
            return;
        }
        Scroll.scrollToInElement(
            element,
            0,
            duration
        );
    },

    scrollToStartOfPage(duration = 500){
        let start = window.scrollY,
            change = 0 - start,
            currentPosition = 0,
            step = 5;

        let animateScroll = function () {
            currentPosition += step;
            window.scrollTo(0, Scroll.easeInOutQuad(currentPosition, start, change, duration));
            if (currentPosition < duration) {
                setTimeout(animateScroll, step);
            }
        };
        animateScroll();
    },

    scrollToEndOfPage(duration = 500){
        let start = window.scrollY,
            change = document.documentElement.scrollHeight - start,
            currentPosition = 0,
            step = 5;

        let animateScroll = function () {
            currentPosition += step;
            window.scrollTo(0, Scroll.easeInOutQuad(currentPosition, start, change, duration));
            if (currentPosition < duration) {
                setTimeout(animateScroll, step);
            }
        };
        animateScroll();
    },

    easeInOutQuad(t, b, c, d) {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
    }
};
export default Scroll;