export const OrderStatusNumeral = {
	IS_NEW: 0,
	IS_WAITING_RESTAURANT: 1,
	IS_IN_PREPARING: 2,
	IS_READY: 3,
	IS_DELIVERY: 4,
	IS_DONE: 5,
	IS_CANCELED: 6
};

export const OrderItemDriverStatusNumeral = {
	IS_NEW: 0,
	IS_DRIVER_ACCEPTED: 1,
	IS_DRIVER_ARRIVED: 2,
	IS_DRIVER_DEPARTED: 3,
	IS_DRIVER_END: 4
};

export const OrderItemRestaurantStatusNumeral = {
	IS_NEW: 0,
	IS_RESTAURANT_PREPARING: 1,
	IS_RESTAURANT_READY: 2
};

export const OrderDeliveryType = {
	IS_DELIVERY: 0,
	IS_PICK_UP: 1,
};

export const OrderType = {
	IS_NOT_CATERING: 0,
	IS_CATERING: 1,
};

export const TipType = {
	IS_TIP_WITH_CREDIT_CARD: 0,
	IS_TIP_WITH_CASH: 1,
};

export default OrderStatusNumeral;
