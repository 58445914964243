import { ORDER_HISTORY } from "../actions/_actionTypes";
import _initialState from "../store/_initialState";

export const orderHistoryReducer = (state = _initialState.orderHistory, action) => {
	switch (action.type) {
		case ORDER_HISTORY.GET_ORDERS:
			return[ ...action.payload];
		
		case ORDER_HISTORY.GET_ORDERS_PAGINATE:
			return [...state,...action.payload];
		
		default:
			return state;
	}
};