import {
	LEFT_MENU,
	LOADING,
	MODAL,
	IMG,
	HEADER,
	ORDER_TYPE_SWITCHERS,
	SETTINGS,
	SET
} from "../actions/_actionTypes";
import initialState from "../store/_initialState";

export const loadingReducer = (state = initialState.loading, action) => {
	switch (action.type) {
		case LOADING.START_LOADING:
			return true;
		case LOADING.STOP_LOADING:
			return false;
		default:
			return state;
	}
};
export const setInnerWidthReducer = (state = initialState.width, action) => {
	switch (action.type) {
		case SET.WIDTH:
			return action.payload;
	
		default:
			return state;
	}
};

export const localLoadingReducer = (state = initialState.localLoading, action) => {
	switch (action.type) {
		case LOADING.START_LOCAL_LOADING:
			return true;
		case LOADING.STOP_LOCAL_LOADING:
			return false;
		default:
			return state;
	}
};

export const leftMenuReducer = (state = initialState.leftMenuVisible, action) => {
	switch (action.type) {
		case LEFT_MENU.SHOW_LEFT_MENU:
			document.body.style.overflow = 'hidden';
			return true;
		case LEFT_MENU.HIDE_LEFT_MENU:
			document.body.style.overflow = 'initial';
			return false;
		default:
			return state;
	}
};

export const showHeaderReducer = (state = initialState.showHeader, action) => {
	switch (action.type) {
		case HEADER.SHOW_HEADER:
			return true;
		case HEADER.HIDE_HEADER:
			return false;
		default:
			return state;
	}
};

export const showModalReducer = (state = initialState.modalVisible, action) => {
	if (action.type === MODAL.SHOW_MODAL) {
		const modalType = action.payload && action.payload.modalType;
		const modalState = action.payload && action.payload.state;
		const modalProps = action.payload && action.payload.modalProps;

		if (modalType in state) {
			return Object.assign({}, state, {
				[modalType]: modalState,
				modalProps: modalState ? modalProps : {}
			})
		}
		return state;

	}
	return state;
};



export const showImgReducer = (state = initialState.modalVisible.showImgVisible, action) => {
	switch (action.type) {
		case IMG.SHOW:
			return true;
		case IMG.HIDE:
			return false;
		default:
			return state;
	}
};
export const setSelectedSettingsReducer = (state = initialState.selectedSettings, action) => {
	switch (action.type) {
		case SETTINGS.SET_SELECTED_SETTINGS:
			return action.payload;
	
		default:
			return state;
	}
};
export const setOrderTypeReducer = (state = initialState.orderType, action) => {
	switch (action.type) {
		case ORDER_TYPE_SWITCHERS.PICK_UP:

			return state = "pick_up"

		case ORDER_TYPE_SWITCHERS.DELIVERY:

			return state = "delivery"

		default:
			return state;
	}
};


