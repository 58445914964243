import initialState from "../store/_initialState";
import { NOTIFICATION } from "../actions/_actionTypes";

export const notificationReducer = (state = initialState.notification, action) => {
  switch (action.type) {
    case NOTIFICATION.ADD:
      return {
        ...state,
        messages: [...state.messages, {
          ...action.payload,
          read: false,
          time: Date.now()
        }]
      };
    case NOTIFICATION.REMOVE:
      return false;
    default:
      return state;
  }
};