import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Paths from "../../../services/Paths";
import { changeSelectedSettings, showModal } from "../../../actions/baseActions";
import ModalTypes from "../../../enums/Modal.Types.Enum";
import { logoutUser } from "../../../actions/userActions";
import userIcon from "../../../img/user.svg"
import { useHistory } from "react-router-dom";

export const NavSignIn = ({ showModal, user, logoutUser, changeSelectedSettings }) => {
  let history = useHistory()
  const [activeDropdown, setActiveDropdown] = useState(false);

  const dropdownMenu = (
    <ul className="dropdown_ul">
      {
        (<>
          <li onClick={() => {
            setActiveDropdown(false)
            history.push(Paths.account)
          }} >
            Edit account
          </li>
          <li className='userDropdownLi' onClick={() => {
            setActiveDropdown(false)
            history.push(Paths.account)
            changeSelectedSettings("orderHistory")
          }}>
            My Orders
          </li>
          <li className='userDropdownLi'
            onClick={() => {
              setActiveDropdown(false)
              history.push(Paths.account)
              changeSelectedSettings("faq")
            }}
          >
            Help
          </li>
        </>)}
      <li className='userDropdownLi' onClick={() => {
        logoutUser()
        history.push(`#`)
      }}>
        Log out
      </li>
    </ul>
  );
  return (
    user.data?.id  ? (
        <>
          <li
            className={"UserAccountLink"}
            onMouseLeave={() => {
              setActiveDropdown(false);
            }}
          >
            <li
            className="account_li"
              onClick={() => setActiveDropdown(true)}
            >
              <img src={userIcon} alt="user" />
            </li>
            {activeDropdown && <div className={"userDropdown"}>{dropdownMenu}</div>}
          </li>
        </> 
    ) : (
      <>
        <li className={"MenuSignUp"} onClick={() => showModal(ModalTypes.REGISTRATION_MODAL)}>Sign up</li>
        <li className={"hoverable"} onClick={() => showModal(ModalTypes.SIGN_IN_MODAL)}><button>Log in</button></li>
      </>
    )
  );
};

NavSignIn.propTypes = {
  showModal: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = {
  showModal,
  logoutUser,
  changeSelectedSettings
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavSignIn);