export const ModalTypes = {
		BASKET_MODAL: 'basket',
		NOTIFICATION_MODAL: 'notification',
		SIGN_IN_MODAL: 'signIn',
		REGISTRATION_MODAL: 'registration',
		FORGOT_PASSWORD_MODAL: 'forgotPassword',
		CREATE_NEW_PASSWORD: 'createNewPassword',
		MAP_MODAL: 'map',
		CHANGE_PASSWORD_MODAL: 'changePassword',
		LOCATION_MODAL:'locationModal'
};

export default ModalTypes;
