import * as CreditCard from '../api/CreditCard.API';
import { handleResponse } from "./Utilities";

export const addNewCreditCard = card => {
  return CreditCard.addNew(card).then(result => handleResponse(
    result,
    undefined,
    () => (result),
    undefined,
    'Card added successfully'
  ))
};

export const makeCardDefault = id => {
  return CreditCard.makeDefault(id).then(result => handleResponse(
    result,
    undefined,
    () => result,
    undefined,
    'Default card success'
  ))
};

export const getList = () => {
  return CreditCard.getList()
    .then(result => result ? result : false);
};

export const deleteCreditCard = id => {
  return CreditCard.deleteCard(id).then(result => handleResponse(
    result,
    undefined,
    () => (result),
    undefined,
    'Card deleted successfully'
  ))
};