import React from 'react';
import arrow from "../../img/button_arrow.png"
const NavigateButtons = ({ onHandleClickForBack, onHandleClick, disabled = true, textBack = "Back", textNext = "Continue" }) => {
    return (
        <div className="button_wrapper">
            <button
                className="back_button"
                onClick={onHandleClickForBack}
            ><img src={arrow} alt="arrow"/> {textBack}</button>
            <button
                className="next_button"
                disabled={disabled}
                onClick={onHandleClick}
                style={disabled ? { opacity: "0.5" } : {}}
            >{textNext}  <img src={arrow} alt="arrow" style={{transform:"rotate(180deg)"}} /> </button>
        </div>
    )
}
export default NavigateButtons;