import * as Http from "./Http.Client";
import { StringifyUrlEncoded } from "./Http.Client";
export const getRestaurants = (offset, limit, model, isAuth, isDelivery) => {
	const queryModel = StringifyUrlEncoded({ page: offset, limit, ...model });	
	return Http.Get(`api/v1/search/restaurants?${queryModel}`, {}, isAuth, isDelivery)
		.then(result => result ? result : false)
		.catch((error)=> error);
};

export const getRestaurantDetails = (restaurantSlug,isAuth=false, isDelivery= false) => {
	return Http.Get(`api/v1/restaurant/details/${restaurantSlug}`,{},isAuth,isDelivery)
		.then(result => result ? result : false);
};

export const getRestaurantProducts = (restaurantSlug,model) => {
	const queryModel = StringifyUrlEncoded({ ...model });
	return Http.Get(`api/v1/search/restaurant/products/${restaurantSlug}?${queryModel}`, {}, false)
		.then(result => result ? result : false);
};



export const getRestaurantType = () => {
	return Http.Get(`api/v1/restaurant-types`)
		.then(result => result ? result : false);
};

export const getReviews = restaurantSlug => {
	return fetch('https://randomuser.me/api/?results=10&inc=name', {
		method: "GET"
	})
		.then(response => {
			return response.json();
		});
};
