import initialState from "../store/_initialState";
import { REGULAR_MENU } from "../actions/_actionTypes";

export const selectedProductReducer = (state = initialState.regularMenu, action) => {
    if (action.type === REGULAR_MENU.SET_SELECTED_PRODUCT) {
        return{
            ...state,
            selectedProduct:action?.data,
        }
    }
    if (action.type === REGULAR_MENU.SHOW_MODAL) {
        return{ 
            ...state,
            showModal:!state.showModal,
        }
    }
    return state;
};



