import React from 'react';
import mobiles from "../../img/mobiles.png"
import appStore from "../../img/appStore.png"
import googlePlay from "../../img/googlePlay.png"
import "../../styles/DownloadApp/DownloadApp.scss"


const DownloadApp = () => {
    return (<div className='download_app_wrapper'>
        <div className='mobile_photo'>

            <img src={mobiles}alt="mobiles" />
        </div>
        <div className="description">
            <div className='header' >Install the app</div>
            <div className="descrition_item">It's never been easier to order food. Look for the finest discounts and you'll be lost in a world of delectable food.</div>
        </div>
        <div className="download_store">
            <img src={appStore}alt="appStore" />
            <img src={googlePlay}alt="googlePlay" />
        </div>
    </div>);
}

export default DownloadApp;

   