import React, {useState} from "react"
import partner1 from "../../img/partners/partner1.png"
import partner2 from "../../img/partners/partner2.png"
import partner3 from "../../img/partners/partner3.png"
import partner4 from "../../img/partners/partner4.png"
import partner5 from "../../img/partners/partner5.png"
import WrapperButton from './../WrapperButton/WrapperButton';
import "../../styles/OurPartners/OurPartners.scss";
import {RegistrationModal} from "./RegisterModal";

const OurPartners = React.memo(() => {
    let partners = [partner1, partner2, partner3, partner4, partner5]
    const [showModal, setShowModal] = useState(false)

    return (
        <>
            <div className={"our_partners_wrapeer"}>
                <div className="our_partners">
                    {partners.map((partner, index) => (
                        <div key={index}>
                            <img src={partner} alt={partner}/>
                        </div>
                    ))}
                </div>
                <WrapperButton
                    OnHandleClick={() => setShowModal(true)}
                    header={'Our  Partners'}
                    desc={'Find a right partner for you'}
                    btn={'Become a partner'}/>
            </div>
            <RegistrationModal showModal={showModal} setShowModal={setShowModal}/>
        </>
    )

})
export default OurPartners