import { FILTER, PAGE } from "./_actionTypes";

export const updateFilter = (filterItems = {}) => (dispatch, getState) => {
	dispatch({
		type: PAGE.ADD_PAGE_COUNT_ONE,
	});
	const state = getState();

	const model = Object.assign({}, state.filter, Object.assign({}, filterItems, {
		isFiltered: true
	}));

	dispatch({
		type: FILTER.UPDATE_FILTER,
		payload: model
	});

	document.dispatchEvent(new CustomEvent('filterUpdate'));
};

export const resetFilter = () => (dispatch, getState) => {
	const state = getState();
	dispatch({
		type: FILTER.RESET_FILTER,
		payload: state.defaultFilterData
	});

	document.dispatchEvent(new CustomEvent('filterReset'));
};

export const setSelectedRestaurantFilter = (data) => dispatch => {
	dispatch({
		type: FILTER.SELECTED_RESTAURANT_FILTER,
		payload: data
	})
}

export const showFilterModal = (bool) => dispatch => {
	dispatch({
		type: FILTER.SHOW_FILTER,
		payload: bool
	})
}
