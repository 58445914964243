import React, {useState} from 'react';
import "../../styles/Footer/Footer.scss";
import envelope from "../../img/envelope.png"
import heart_footer from "../../img/heart_footer.png"
import Symbol from "../../img/Symbol.png"
import {useHistory} from 'react-router';
import {connect} from 'react-redux';
import {changeSelectedSettings} from "../../actions/baseActions";

const Footer = ({changeSelectedSettings}) => {
    const history = useHistory()
    const [ifHidden, setIfHidden] = useState("block")
    const company = [
        "About us",
        "Team",
        "Careers",
        "Blog"
    ];
    const contact = [
        "Help & Support",
        "Partner with us",
        "Ride with us"
    ];
    const legal = [
        "Term & Conditions",
        "Refund & Cancellation",
        "Privacy Policy",
        "Cookie Policy"
    ]
    const iconHide = (e) => {
        e.target.value ? setIfHidden("none") : setIfHidden("block")
    }
    return (<div className="footer_wrapper">

            <div className="footer_items">
                <div className='items_header_wrapper'>

                    <div className='items_header'>Company</div>
                    <div>
                        {company.map((item, key) => (
                            <div key={key} className="item_cantent">
                                {item}
                            </div>
                        ))}
                    </div>
                </div>
                <div className='items_header_wrapper'>
                    <div className='items_header'>Contact</div>
                    <div>
                        {contact.map((item, key) => (
                            <div key={key} className="item_cantent">
                                {item}
                            </div>
                        ))}
                    </div>
                </div>
                <div className='items_header_wrapper'>

                    <div className='items_header'>Legal</div>
                    <div>{legal.map((item, key) => {
                        return <div key={key}
                                    onClick={() => {
                                        if (item === "Term & Conditions") {
                                            changeSelectedSettings("termsConditions");
                                            history.push("/account")
                                        }
                                        if (item === "Privacy Policy" || item === "Cookie Policy") {
                                            changeSelectedSettings("privacyPolicy");
                                            history.push("/account")
                                        }
                                    }}
                                    className="item_cantent">
                            {item}
                        </div>
                    })}
                    </div>
                </div>
            </div>
            <div className="input_area">
                <div>Receive exclusive offers in your mailbox</div>
                <div className='input_wrapper'>
                    <img src={envelope} className="envelope" alt={"envelope"} style={{display: ifHidden}}/>
                    <input placeholder="Enter Your email" onChange={(e) => iconHide(e)}/>
                    <button>Subscribe</button>
                </div>
            </div>
            <div className="rights_area">
                <hr/>
                <div className='rights_area_description'>
                    <div>All rights Reserved <img alt="symbol" src={Symbol}/> <span>MeaMeal, 2022</span></div>
                    <img className='footer_heart' alt="footer_heart" src={heart_footer}/>
                </div>

            </div>
        </div>
    )
}
const mapStateToProps = _ => ({});

const mapDispatchToProps = {
    changeSelectedSettings
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Footer);
