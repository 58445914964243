import React from "react";
import Header from "../../container/Header/Header"
import HomePageMenu from "../../container/HomePageMenu/HomePageMenu";
import OurPartners from "../../container/OurPartners/OurPartners";
import SeeDetails from "../../container/SeeDetails/SeeDetails";
import Custumers from "../../container/Custumers/Custumers";
import DownloadApp from './../../container/DownloadApp/DownloadApp';
import { connect } from "react-redux"
const Preview = ({ width, modalVisible }) => {
	return <>
		<Header />
		<HomePageMenu />
		<OurPartners />
		<SeeDetails />
		<Custumers />
		<DownloadApp />
	</>
}
const mapStateToProps = state => ({
	width: state.width,
	modalVisible: state.modalVisible,
});

const mapDispatchToProps = {
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Preview);

