import React from 'react';
import  "../../styles/WrapperButton/WrapperButton.scss";

const WrapperButton = ({header,desc,btn,clsName,OnHandleClick}) => {
    return (<div className={`${clsName} wrapper_button`} >
        <span>{header}</span>
        <p>{desc}</p>
        <button className='wrapper_btn' onClick={OnHandleClick}>{btn}</button>
    </div>);
}

export default WrapperButton;