import React, {memo} from "react";
import PropTypes from "prop-types";
import DefaultBackgroundImg from "../../img/BannerHome.png";
import AddAddress from "../../components/HelpersComponents/Add.Address.Component";
import {updateOrderDeliveryType, updateOrderType} from "../../actions/orderActions";
import {connect} from "react-redux";
import {OrderDeliveryType} from "../../enums/Order.Status.Enum";
import {updateFilterData} from "../../actions/baseActions";
import delivery from "../../img/delivery.svg";
import pickup from "../../img/pickup.svg";

const Header = memo(
    ({
         backgroundImg = DefaultBackgroundImg,
         showLocation = true,
         onAddressConfirmed = (f) => f,
         updateOrderDeliveryType = (f) => f,
         isDelivery = true,
     }) => {
        return (
            <header style={{backgroundImage: `url(${backgroundImg})`}}>
                <div className="HomeSearchComponent">
                    <div className="get_food_type_wrapper">
                        <div className="get_food_type_desc">
                            <h2>
                                Fastest <span>Delivery</span> & Easy <span>Pickup</span>
                            </h2>
                            <p>
                                Best cooks and best delivery team all at your service. Hot and
                                fresh food will reach you in 60 minutes.
                            </p>
                        </div>
                        {showLocation && (
                            <div className={"getFoodType"}>
                                <div>
                                    <div>
                                        <button
                                            className={isDelivery ? "active" : ""}
                                            onClick={() => updateOrderDeliveryType(OrderDeliveryType.IS_DELIVERY)}
                                        >
                                            <img src={delivery} alt=""/>
                                            Delivery
                                        </button>
                                        <button
                                            className={!isDelivery ? "active" : ""}
                                            onClick={() => updateOrderDeliveryType(OrderDeliveryType.IS_PICK_UP)}
                                        >
                                            <img src={pickup} alt="Pick Up"/>
                                            Pick Up
                                        </button>
                                    </div>
                                </div>
                                <AddAddress onAddressConfirmed={onAddressConfirmed}/>
                            </div>
                        )}
                    </div>
                </div>
            </header>
        );
    }
);

Header.propTypes = {
    backgroundImg: PropTypes.string,
    title: PropTypes.string,
    showLocation: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    isDelivery: state.isDelivery,
    isCatering: state.isCatering,
});

const mapDispatchToProps = {
    updateOrderType,
    updateOrderDeliveryType,
    updateFilterData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
