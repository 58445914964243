import React, { useState, useRef, useEffect, memo } from 'react';
import "./Custumers.scss";
import Custumer from "../../img/Custumer.png"
import Custumer1 from "../../img/custumer1.webp"
import Custumer2 from "../../img/custumer2.jfif"
import Custumer3 from "../../img/custumer3.jfif"
import star from "../../img/star.png"
import emptyStar from "../../img/emptyStar.png"
import arrow from "../../img/arrow.png"
const Custumers = memo(() => {
    const [elWeight, setElWeight] = useState(1920)
    const elementWeight = useRef(null);
    const [selectedCustumer, setSelectedCustumer] = useState(2)
    let custumers = [
        {
            number: 0,
            img: Custumer,
            name: "Leo",
            profession: "Lead Designer",
            desc: "Best Food ordering app now acquired by MeaMeal in USA",
            moreDecs: "Employees can order their preferred food for lunch easily from the app. The app is clear and offers the possibility to scan and search by category of restaurants.",
            rating: 5
        },
        {
            number: 1,
            img: Custumer1,
            name: "Mayq",
            profession: "PR Manager",
            desc: "MeaMeal- one of the most convenient delivery services",
            moreDecs: "MeaMeal provides such a wide selection of restaurants, including some of the best and trendiest ones around. You get delicious food that usually arrives nice and hot at minimal extra expense (the delivery fees are minimal).",
            rating: 5
        },
        {
            number: 2,
            img: Custumer2,
            name: "Maria",
            profession: "Developer",
            desc: "Eat and Enjoy while sitting in Home",
            moreDecs: "Easy to search for restaurants and simple user interface are two good qualities about the uber eats app. Also, the delivery time is quite less compared to other food delivery apps.",
            rating: 4
        },
        {
            number: 3,
            img: Custumer3,
            name: "Jack",
            profession: "Lead Designer",
            desc: "It was a very good experience",
            moreDecs: "The user Interface is very friendly it is very easy to search for the Restaurants and book the food in the uber eats application. The delivery is very fast when compared to its compitators. In case of any support it is very easy to connect to Customer Service.",
            rating: 5
        },
    ]
    useEffect(() => {
        handleResize()
    }, [])
    const setRaiting = (r) => {
        let ratItem = [0, 0, 0, 0, 0]
        for (let index = 0; index < r; index++) {
            index < r ? ratItem[index] = 1 : ratItem[index] = 0
        }
        return ratItem
    }
    const handleResize = () => {
        setElWeight(elementWeight?.current?.clientWidth)
    };
    window.addEventListener("resize", handleResize, false);
    return (
        <div className='custumers_wrapper' ref={elementWeight}>
            <div className='custumers_header'>See why customers love us</div>
            <div className='custumers_card'>
                {
                    custumers.map((i, key) => {

                        let cls = i.number === selectedCustumer ? "active" : "";

                        if (elWeight < 1200 && i.number === selectedCustumer) {
                            return (<div className={`custumers_card_wrapper ${cls}`} key={key}
                            // onMouseEnter={() => {
                            //         setSelectedCustumer(i.number)
                            //     }}
                            >
                                <div className="custumers_card_info">
                                    <img
                                        className="profile_image"
                                        src={custumers[selectedCustumer].img}
                                        alt={custumers[selectedCustumer].img}
                                    />
                                    <div className="custumer_card_desc">
                                        <div className="custumer_card_name">{custumers[selectedCustumer].name}</div>
                                        <div className="custumer_card_profession">{custumers[selectedCustumer].profession}</div>
                                    </div>
                                    <div className='custumer_rating' >{
                                        setRaiting(custumers[selectedCustumer].rating).map((i, k) => {
                                            return i ? <img src={star} alt="star" key={k} /> : <img
                                                src={emptyStar} alt="emptyStar" key={k} />
                                        })
                                    }</div>
                                </div>
                                <div className="desc">{i.desc}</div>
                                <div className='moreDesc'>{i.moreDecs}</div>
                            </div>)
                        }

                        if (selectedCustumer === 0 && i.number < 3 && elWeight > 1200) {
                            return (<div className={`custumers_card_wrapper ${cls}`} key={key}
                            // onMouseEnter={() => {
                            //         setSelectedCustumer(i.number)
                            //     }}
                            >
                                <div className="custumers_card_info">
                                    <img src={i.img} alt={i.img} />
                                    <div className="custumer_card_desc">
                                        <div className="custumer_card_name">{i.name}</div>
                                        <div className="custumer_card_profession">{i.profession}</div>
                                    </div>
                                    <div className='custumer_rating' >{
                                        setRaiting(i.rating).map((i, k) => {
                                            return i ? <img src={star} alt="star" key={k} /> : <img src={emptyStar} alt="emptyStar" key={k} />
                                        })
                                    }</div>
                                </div>
                                <div className="desc">{i.desc}</div>
                                <div className='moreDesc'>{i.moreDecs}</div>
                            </div>)
                        }
                        if (selectedCustumer === custumers.length - 1 && i.number >= custumers.length - 3 && elWeight > 1200) {
                            return <div className={`custumers_card_wrapper ${cls}`} key={key}
                            //    onMouseEnter={() => {
                            //         setSelectedCustumer(i.number)
                            //     }}
                            >
                                <div className="custumers_card_info">
                                    <img src={i.img} alt={i.img} />
                                    <div className="custumer_card_desc">
                                        <div className="custumer_card_name">{i.name}</div>
                                        <div className="custumer_card_profession">{i.profession}</div>
                                    </div>
                                    <div className='custumer_rating' >{
                                        setRaiting(i.rating).map((i, k) => {
                                            return i ? <img src={star} alt="star" key={k} /> : <img src={emptyStar} alt="emptyStar" key={k} />
                                        })
                                    }</div>
                                </div>
                                <div className="desc">{i.desc}</div>
                                <div className='moreDesc'>{i.moreDecs}</div>
                            </div>
                        }
                        if ((selectedCustumer === i.number + 1 || selectedCustumer === i.number - 1 || selectedCustumer === i.number) && elWeight > 1200) {
                            return (
                                <div className={`custumers_card_wrapper ${cls}`} key={key}
                                // onMouseEnter={() => {
                                //     setSelectedCustumer(i.number)
                                // }}
                                >
                                    <div className="custumers_card_info">
                                        <img src={i.img} alt={i.img} />
                                        <div className="custumer_card_desc">
                                            <div className="custumer_card_name">{i.name}</div>
                                            <div className="custumer_card_profession">{i.profession}</div>
                                        </div>
                                        <div className='custumer_rating' >{
                                            setRaiting(i.rating).map((i, k) => {
                                                return i ? <img src={star} alt="star" key={k} /> : <img src={emptyStar} alt="emptyStar" key={k} />
                                            })
                                        }</div>
                                    </div>
                                    <div className="desc">{i.desc}</div>
                                    <div className='moreDesc'>{i.moreDecs}</div>
                                </div>
                            )
                        }
                        return null
                    })
                }
            </div>
            <div className='pagination'>
                <img className="arrow_left" src={arrow} alt="arrow" onClick={() => {
                    if (selectedCustumer >= 1) {
                        return setSelectedCustumer(selectedCustumer - 1)
                    }
                }} />
                {custumers.map((i, key) => {

                    return <span className={`pagination_item  ${i.number === selectedCustumer ? "activCycle" : ""}`} key={key}
                        onClick={() => {
                            setSelectedCustumer(i.number)
                        }}
                    ></span>
                })}
                <img className="arrow_right" alt="arrow" src={arrow} onClick={() => {
                    if (selectedCustumer < custumers.length - 1) {

                        return setSelectedCustumer(selectedCustumer + 1)
                    }
                }} />
            </div>
        </div>
    )
})
export default Custumers;
