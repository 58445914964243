import React from 'react';

import star from "../../img/star.png";
import emptyStar from "../../img/emptyStar.png"

import "../../styles/ReviewsMaker/ReviewsMaker.scss"

const ReviewsMaker = ({ rating, count, type }) => {
    const printRating = (rating) => {
        let ratingArr = [0, 0, 0, 0, 0];
        for (let index = 0; index < rating; index++) {
            ratingArr[index] = 1
        }
        return ratingArr
    }
    return <div className="item_rating">
        {printRating(rating).map((i, k) => {
                return i === 1 ?
                    <img className={type?"img":""} src={star} alt="star" key={k} />
                    :
                    <img className={type?"img":""} src={emptyStar} alt="emptyStar" key={k} />
            })}
       {count ? <span>({count} reviews)</span> : null}
    </div>
}
export default ReviewsMaker