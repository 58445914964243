import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "../../styles/Switchers/Switchers.scss";
import { useHistory } from "react-router-dom";
import { updateFilter } from "../../actions/filterActions";
import { getCategories } from "../../actions/baseActions";
const CateringFoodType = ({
  isDelivery,
  updateFilter,
  selectedCategories,
  categories,
  address,
  getCategories,
}) => {
  const [displayNumber, setDisplayNumber] = useState(7);
  const history = useHistory();
  useEffect(() => {
    if (!address && isDelivery) {
      getCategories(false, true);
    }
    if (address && isDelivery) {
      getCategories(isDelivery);
    } else if (!isDelivery) {
      getCategories(isDelivery);
    }
  }, [address, isDelivery]);
  return categories.length ? (
    <div className="categories">
      {categories?.map(
        (category, index) =>
          index <= displayNumber && (
            <div
              className={`category ${index}`}
              key={index}
              onClick={() => {
                history.push("/restaurant-menu");
                updateFilter({
                  selectedCategories: [category.slug],
                });
              }}
            >
              <div
                className={
                  selectedCategories.filter((i) => i === category.slug)[0]
                    ? "selected"
                    : ""
                }
                style={{ backgroundImage: `url(${category?.downloadLink})` }}
              ></div>
              <p>{category?.name}</p>
            </div>
          )
      )}
      <div
        className={`category`}
        onClick={() => {
          if (displayNumber + 9 <= categories.length - 1) {
            setDisplayNumber(displayNumber + 8);
          } else {
            setDisplayNumber(categories.length);
          }
        }}
      >
        {!(categories.length === displayNumber) && (
          <div className="chenger">
            <b>Other</b>
          </div>
        )}
      </div>
    </div>
  ) : null;
};
const mapStateToProps = (state) => ({
  restaurants: state.restaurants,
  selectedCategories: state.filter.selectedCategories,
  address: state.currentLocation.address.address,
  categories: state.categories,
  isDelivery: state.isDelivery,
});

const mapDispatchToProps = {
  updateFilter,
  getCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(CateringFoodType);
