import initialState from "../store/_initialState";
import { CHECKOUT_ORDER } from "../actions/_actionTypes";

export const checkoutReducer = (state = initialState.checkout, action) => {
    if (action.type === CHECKOUT_ORDER.ADD_USER_INFO) {
        return {
            ...state,
            userInfo: action.payload,
        }
    }
    if (action.type === CHECKOUT_ORDER.DONATE) {
        return {
            ...state,
            donate: action.payload,
        }
    }
    if (action.type === CHECKOUT_ORDER.FINALLY) {
        return {
            ...state,
            uuidCode: action.payload,
        }
    }
    if (action.type === CHECKOUT_ORDER.ADD_USER_LOCATION) {
        return {
            ...state,
            location: action.payload,
        }
    }
    if (action.type === CHECKOUT_ORDER.STEP) {
        return {
            ...state,
            checkoutStep: action.payload,
        }
    }
    if (action.type === CHECKOUT_ORDER.CHANGE_ARRIVAL_TIME) {
        return {
            ...state,
            schedule: {
                schedule: action.payload.bool,
                scheduleDate: {
                    ...state.schedule.scheduleDate,
                    ...action.payload.value
                }
            },
        }
    }
    return state;
};