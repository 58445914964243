import { PAGE, RESTAURANTS } from "./_actionTypes";

export const addPageCount = () => (dispatch) => {
	dispatch({
		type: PAGE.ADD_PAGE_COUNT,
        payload:1
	});
};
export const addRestaurantPageCount = () => (dispatch) => {
	dispatch({
		type: RESTAURANTS.SET_RESTAURANT_PAGE_COUNT,
        payload:1
	});
};
export const addPageCountOne = () => (dispatch) => {
	dispatch({
		type: PAGE.ADD_PAGE_COUNT_ONE,
	});
};
