import React, { memo } from "react";
import "../../styles/HomePageMenu/HomePageMenu.scss"
import regularMenu from "../../img/regularMenu.png"
import cateringMenu from "../../img/cateringMenu.png"

import { withRouter } from "react-router";

import { connect } from "react-redux";
import { messageType, showMessage } from "../../services/Utilities";
import { showModal } from "../../actions/baseActions";
import ModalTypes from "../../enums/Modal.Types.Enum";
import { useHistory } from "react-router-dom";
const HomePageMenu = memo(({ showModal, address }) => {
    const history = useHistory()
    let menus = [
        {
            cls: "catering_menu",
            img: cateringMenu,
            header: "Catering menu",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit facilisi tortor vitae est vehicula urna non ligula quis.",
            btn: "See menu",
            to: "/catering-section"
        },
        {
            cls: "regular_menu",
            img: regularMenu,
            header: "Restaurant menu",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit facilisi tortor vitae est vehicula urna non ligula quis.",
            btn: "See menu",
            to: "/restaurant-menu"
        },
    ];
    return (<div className={'home_page_menu_wrapper'}  >
        {
            menus.map(({ img, header, desc, btn, cls, to }, index) => {
                return (
                    <div key={index} className={`home_page_menu ${cls}`} >
                        <img src={img} alt={img} />
                        <div>
                            <h3>{header}</h3>
                            <p>{desc}</p>

                            <button onClick={() => {
                                if (to === "/catering-section") {
                                    showMessage(messageType.info, 'Comming soon')
                                }
                                if (to === "/restaurant-menu") {
                                    if (!Object.keys(address).length) {
                                        showModal(ModalTypes.LOCATION_MODAL, true)
                                    }else{
                                        history.push("/restaurant-menu")
                                    }
                                }
                            }
                            }>{btn}</button>
                        </div>
                    </div>
                )
            })
        }
    </div>)

});
const mapStateToProps = (state) => ({
    address: state.currentLocation.address
});
const mapDispatchToProps = { showModal };
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(HomePageMenu));