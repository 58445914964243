import {LOCATION} from "./_actionTypes";

export const updateLocation = address => dispatch => {
    dispatch({
        type: LOCATION.UPDATE_USER_LOCATION,
        payload: address
    });
};

export const removeLocation = () => dispatch => {
    dispatch({
        type: LOCATION.REMOVE_USER_LOCATION
    })
}

export const updateIsAddressSet = (state = true) => dispatch => {
    dispatch({
        type: LOCATION.IS_ADDRESS_SET,
        payload: state
    })
};
