export const PartnerTabs = {
  orders: "orders",
  details: "details",
  products: "products",
  categories: "categories",
  edit_profile: "edit_profile",
  stopTakingOrders: "stopTakingOrders",
  locations: "locations",
  users: "users",
  reviews: "reviews",
  driver: "driver",
  promotions: "promotions",
  caterings: "caterings",
  employees: "employees",
  balance: "balance"
};

export default PartnerTabs;
