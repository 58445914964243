import { CHECKOUT_ORDER } from "./_actionTypes";

export const addUserLocation = location => dispatch => {
	dispatch({
		type: CHECKOUT_ORDER.ADD_USER_LOCATION,
		payload: location
	})
};
export const addUserInfo = info => dispatch => {
	dispatch({
		type: CHECKOUT_ORDER.ADD_USER_INFO,
		payload: info
	})
};
export const sendUuidCode = code => dispatch => {
	dispatch({
		type: CHECKOUT_ORDER.FINALLY,
		payload: code
	})
};
export const addDonate = bool => dispatch => {
	dispatch({
		type: CHECKOUT_ORDER.DONATE,
		payload: bool
	})
};
export const changeStep = step => dispatch => {
	dispatch({
		type: CHECKOUT_ORDER.STEP,
		payload: step
	})
};
export const changeArrivalTime = data => dispatch => {
	dispatch({
		type: CHECKOUT_ORDER.CHANGE_ARRIVAL_TIME,
		payload: {
			bool: data.bool,
			value: data.value
		}
	})
};

