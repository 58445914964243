import React from "react";
import Modal from "../HelpersComponents/Modal.Component";
import PropTypes from "prop-types";
import { showModal } from "../../actions/baseActions";
import { connect } from "react-redux";
import ModalTypes from "../../enums/Modal.Types.Enum";
import moment from 'moment';
import delivery from "../../img/ic_patner_with_us.svg";

const NotificationsModal = ({notificationModalVisible, showModal, messages}) => {
		const [, updateState] = React.useState();
		const forceUpdate = React.useCallback(() => updateState({}), []);
		//render every minute to update notifications time
		setTimeout(() => forceUpdate(), 1000 * 60)
		
		return (
				<Modal
						className={"CustomNotificationModal"}
						prefixCls={"Modal"}
						visible={notificationModalVisible}
						onCancel={() => showModal(ModalTypes.NOTIFICATION_MODAL, false)}
				>
						<p className={"NotificationTitle"}>Notifications</p>
						{messages.length ?
								<ul>
										{
												messages.map((message, key) => {
														//1598281369654
														const days = moment(Date.now()).diff(message.time, 'days');
														const minutes = moment(Date.now()).diff(message.time, 'minutes')
														return (
																<li key={key}>
																		<span
																				className="NotificationTime">{!!days && days + ' d'} {(!!minutes ? minutes : 1) + ' min'} ago</span>
																		<p className={"NotificationDescription"}>{message.title}</p>
																		<p>{message.body}</p>
																		<img src={delivery} alt="delivery"/>
																</li>
														)
												})
										}
								</ul>
								:
								<div className='notFound'>
										<div>
												<p className={'NotificationDescription'}>No notifications found</p>
												<img src={delivery} alt="delivery"/>
										</div>
								</div>
						}
				</Modal>
		);
};

NotificationsModal.propTypes = {
		notificationModalVisible: PropTypes.bool.isRequired,
		showModal: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
		notificationModalVisible: state.modalVisible.notification,
		messages: state.notification.messages
});

const mapDispatchToProps = {showModal};

export default connect(
		mapStateToProps,
		mapDispatchToProps
)(NotificationsModal);
