
export const DefaultFilterItems = {
	isFiltered: false,
	isOpenNow: false,
	priceMin: null,
	priceMax: null,
	selectedCategories: [],
	distanceMax:10,
	dietFriendly:[],
	kitchenTypes:[]
};

export const DefaultFilterData = {
	price: {
		min: 0.1,
		max: 60
	},
	dietFriendly:[],
	kitchenTypes:[],

};
