import React from 'react';
import error from "../../img/errorBoundry.png"
import { withRouter } from "react-router-dom";
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null
        };
    }


    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo);
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.

        return { error }
    }
    render() {
        if (this.state.error) {
            // You can render any custom fallback UI
            return <>
                <div className='error_boundary'>
                    <p>Oops!</p>
                    <h1>Something went wrong.</h1>
                    <img src={error} alt="error" />
                    <h1>Server Error 500, we apologise and are fixing the problem.<br />
                        Please try again at a later stage.</h1>
                    <button onClick={() => {
                         window.location.reload()
                    }} >Refresh</button>
                </div>
            </>
        }
        return this.props.children;

    }
}
export default withRouter(ErrorBoundary);
