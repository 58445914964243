import * as Http from "./Http.Client";

export const signIn = (model = {}) =>
	Http.Post(
		'api/v1/login',
		{
			"Content-Type": "application/x-www-form-urlencoded",

		},
		Http.StringifyUrlEncoded(model),
		false,
		true
	)
		.then(Http.HandleResponseState).catch(() => {
			signOut()
		})


export const signUp = (model = {}) =>
	Http.Put('api/v1/user', {}, model)
		.then(Http.HandleResponseState);

export const signOut = () =>
	Http.Post('api/v1/logout', {}, {}, true)
		.then(Http.HandleResponseState);

export const getCurrentUser = () =>
	Http.Get('api/v1/user', {}, true)
		.then(Http.HandleResponseState);

export const editUserInfo = (model = {}) =>
	Http.Post('api/v1/users', {}, model, true)
		.then(Http.HandleResponseState);

export const requestVerificationCode = formData =>
	Http.Post('api/v1/request/forgot-password/code', {}, formData, false, true)
		.then(Http.HandleResponseState);

export const forgotPassword = model =>
	Http.Post('api/v1/users/forgots/passwords', {}, model, false)
		.then(Http.HandleResponseState);

export const changePassword = (model = {}) =>
	Http.Post('api/v1/forgot-password', {}, model, false)
		.then(Http.HandleResponseState);

/**
 * Firebase Token
 */
export const sendFBTokenToServer = formData =>
	Http.Post('api/v1/users/sets/firebases/tokens', {}, formData, true, true)
		.then(Http.HandleResponseState);

export const sendUpdateUser = (formData) =>
	Http.Post('api/v1/user/update', {}, formData, true, false)
		.then(Http.HandleResponseState);

export const sendUserPhoto = (formData) =>
	Http.Post('api/v1/user/update/avatar', {}, formData, true, true)
		.then(Http.HandleResponseState);

