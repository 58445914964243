import * as OrderApi from "../api/Order.API";
import { handleResponse, toFormData } from "./Utilities";

export const sendCustomerFeedback = (model, orderSlug) => {
  return OrderApi.sendOrderFeedback(model, orderSlug).then(result => handleResponse(
    result,
    undefined,
    () => (result.data),
    undefined,
    "Your opinion has been successfully sent."
  ));
};

export const getCouponData = coupon => {
  return OrderApi.getCouponData(coupon).then(result => handleResponse(
    result,
    undefined,
    () => (result.data)
  ));
};


export const setAddItemProduct = (product, slug) => {
  const formData = toFormData({ product, slug });
  return OrderApi.setAddItemProduct(formData).then(result => handleResponse(
    result,
    undefined,
    () => (result)
  ));
};
