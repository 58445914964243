import {BASKET, CLIENT, LOADING, LOCATION, MODAL, USER} from "./_actionTypes";
import Config from "Config";
import {messageType, showMessage, getErrorsFromResponse, handleResponse} from "../services/Utilities";
import * as UserAPI from "../api/User.API";
import * as UserService from "../services/User.Service";
import * as BasketService from "../services/Basket.Service";
import {ModalTypes} from "./../enums/Modal.Types.Enum";

/**
 *  SIGN_IN / SING_UP / SIGN_OUT
 */
export const loginUser = (model = {}) => dispatch => {
    model = Object.assign({
        grant_type: "password",
        client_id: Config.ClientId,
        client_secret: Config.ClientSecret
    }, model);

    dispatch({type: LOADING.START_LOADING});

    return UserAPI.signIn(model)
        .then(result => {
            if (result) {
                if (result.success && 'access_token' in result.data) {
                    UserService.setAuthData(result.data);
                    return result.data;
                }
                showMessage(messageType.error, getErrorsFromResponse(result), 5);
                throw new Error(getErrorsFromResponse(result));
            }
            showMessage(messageType.error, 'There was an error. Please try again.');
            throw new Error("There was an error. Please try again.");
        })
        .then(UserAPI.getCurrentUser)
        .then(result => {
            if (result) {
                if (result.success) {
                    UserService.updateAuthData(result.data);
                    return result.data;
                }
                showMessage(messageType.error, getErrorsFromResponse(result), 5);
                throw new Error(getErrorsFromResponse(result));
            }
            showMessage(messageType.error, 'There was an error. Please try again.');
            throw new Error("There was an error. Please try again");
        })
        .then(user => {
            if (user && user.id) {
                dispatch({
                    type: USER.LOGGED_IN,
                    payload: user
                });

                document.dispatchEvent(new CustomEvent('successLoggedIn', {
                    detail: user
                }));

                showMessage(messageType.success, "You have successfully logged in.");
                return user;
            }
            showMessage(messageType.error, 'There was an error. Please try again.');
            throw new Error("There was an error. Please try again");
        })
        .catch(err => {
            UserService.removeAuthData();
            return false;
        })
        .finally(() => {
            dispatch({type: LOADING.STOP_LOADING});
        });
};

export const logoutUser = () => dispatch => {
    dispatch({type: LOADING.START_LOADING});
    return UserAPI.signOut()
        .then(result => handleResponse(
            result,
            () => dispatch({type: LOADING.STOP_LOADING}),
            () => {
                UserService.removeAuthData();
                UserService.removeLocationData();
                BasketService.clearBasket();

                dispatch({
                    type: USER.LOGGED_OUT
                });

                dispatch({
                    type: BASKET.CLEAR_CART
                });

                dispatch({
                    type: LOCATION.REMOVE_USER_LOCATION
                })

                document.dispatchEvent(new CustomEvent('successLogOut'));

                return result.data;
            },
            undefined,
            'You have successfully logged out.',
            undefined
        ))
        .catch((err => console.log(err)));
};

export const registerUser = (model = {}) => dispatch => {
    dispatch({type: LOADING.START_LOADING});

    return UserAPI.signUp(model)
        .then(result => {
            if (result) {
                if (result.success) {
                    showMessage(messageType.success, "You have successfully registered.");
                    return result.data
                }
                showMessage(messageType.error, getErrorsFromResponse(result), 5);
                return false;
            }
            showMessage(messageType.error, 'There was an error while registering your account.');
            return false;
        })
        .catch(err => {
            console.log("Error", err);
            return false;
        })
        .finally(() => {
            dispatch({type: LOADING.STOP_LOADING});
        });
};

/**
 *    Personal Information
 */
export const editUserInfo = (model = {}) => dispatch => {
    dispatch({type: LOADING.START_LOADING});

    return UserAPI.editUserInfo(model)
        .then(result => handleResponse(
            result,
            () => dispatch({type: LOADING.STOP_LOADING}),
            () => {
                dispatch({
                    type: USER.UPDATE_PERSONAL_INFORMATION,
                    payload: result.data
                });
                UserService.updateAuthData(result.data);
                return result.data
            },
            undefined,
            'Personal information has been successfully updated.',
            'There was an error while registering your account.'
        ))
        .catch(err => {
            console.log("Error", err);
            return false;
        });
};

export const userChangePassword = model => dispatch => {
    dispatch({type: LOADING.START_LOADING});

    return UserAPI.changePassword(model)
        .then(result => {

            handleResponse(
                result,
                () => dispatch({type: LOADING.STOP_LOADING}),
                undefined,
                undefined,
                'You password was successfully change.',
                'There was an error. Please try again.'
            );
            dispatch({
                type: MODAL.SHOW_MODAL,
                payload: {modalType: ModalTypes.CHANGE_PASSWORD_MODAL, modalProps: false}
            })
            localStorage.removeItem('Email')
        })
        .catch(err => console.log(err));
};

/**
 * Driver
 */


/**
 * Client
 */

export const clearClientOrders = () => dispatch => {
    dispatch({type: CLIENT.CLEAR_ORDERS})
};
