import {ADDRESS_NOTE, FILTER, ORDER_DELIVERY_TYPE, ORDER_TYPE, ORDER_UPDATE} from "./_actionTypes";
import {OrderDeliveryType, OrderType} from "../enums/Order.Status.Enum";

export const updateOrderDeliveryType = (orderDeliveryType = OrderDeliveryType.IS_DELIVERY) => (dispatch) => {
	document.dispatchEvent(new CustomEvent('updateOrderDeliveryType', {
		detail: orderDeliveryType
	}));
	dispatch({
		type: ORDER_DELIVERY_TYPE.UPDATE_ORDER_DELIVERY_TYPE,
		payload: orderDeliveryType
	});
};

export const updateOrderType = (orderType = OrderType.IS_NOT_CATERING) => (dispatch, getState) => {

	document.dispatchEvent(new CustomEvent('updateOrderType', {
		detail: orderType
	}));

	dispatch({
		type: ORDER_TYPE.UPDATE_ORDER_TYPE,
		payload: orderType
	});
	
	// reset Filter
	const state = getState();
	dispatch({
		type: FILTER.RESET_FILTER,
		payload: state.defaultFilterData
	});

	document.dispatchEvent(new CustomEvent('filterReset'));
	localStorage.setItem('isCatering', JSON.stringify(!!orderType))
};

export const updateDriverOrderProperty = (orderSlug,property, model) => dispatch => {
		dispatch({
				type: ORDER_UPDATE.UPDATE_DRIVER_ORDER_PROPERTY,
				payload: {orderSlug, property, model}
		});
}

export const updateCustomerOrderProperty = (orderSlug,property, model) => dispatch => {
		dispatch({
				type: ORDER_UPDATE.UPDATE_CUSTOMER_ORDER_PROPERTY,
				payload: {orderSlug, property, model}
		});
}

export const setAddressNotes = notes => dispatch => {
		dispatch({
				type: ADDRESS_NOTE.SET_ADDRESS_NOTE,
				payload: {notes}
		});
}
