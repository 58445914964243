import * as Http from "./Http.Client";
import { HandleResponseState } from "./Http.Client";

export const submitOrder = model => {
	return Http.Put('api/v1/order', {}, model, true)
		.then(result => result ? result : false);
};
export const reOrder = orderSlug => {
	return Http.Post(`api/v1/order/reorder/${orderSlug}`, {},true, true)
		.then(result => result ? result : false);
};

export const submitCateringOrder = model => {
	return Http.Put('api/v1/order/catering', {}, model, true)
		.then(result => result ? result : false);
}

export const getLastReviews = () => {
	return Http.Get('api/v1/review/last', {})
		.then(result => result ? result : false);
};

export const getClientOrders = (offset = 1, limit = 5,) =>
	Http.Get(`api/v1/client/orders?offset=${offset}&limit=${limit}`, {}, true)
		.then(result => result ? result : false)

export const sendOrderFeedback = (data, orderSlug) =>
	Http.Post(`api/v1/order/${orderSlug}/feedback`, {}, data, true)
		.then(result => result ? result : false)
export const getOrderFeedback = (orderSlug) =>
	Http.Get(`api/v1/order/${orderSlug}/feedback`, {}, true)
		.then(result => result ? result : false)

export const getSingleOrderById = (id) =>
	Http.Get(`api/v1/order/${id}/single`, {}, true)
		.then(result => result ? result : false)

export const sendCustomerFeedback = (model, orderSlug) =>
	Http.Put(`api/v1/orders/${orderSlug}/feedback`, {}, model, true)
		.then(HandleResponseState);

export const getCouponData = code =>
	Http.Post(`api/v1/orders/checks/coupons`, {}, { code }, true)
		.then(HandleResponseState);

export const setAddItemProduct = code =>
	Http.Post(`api/v1/orders/add_product_to_order`, {}, code, true, true)
		.then(HandleResponseState);
