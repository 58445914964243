import initialState from "../store/_initialState";
import { CATEGORIES, PRODUCTS } from "../actions/_actionTypes";

export const categoriesReducer = (state = initialState.categories, action) => {
	if (action.type === CATEGORIES.SET_CATEGORIES) {
		return [...action.payload]
	};
	return state;
};

export const productsReducer = (state = initialState.products, action) => {
	switch (action.type) {
		case PRODUCTS.SET_PRODUCTS:
			return action.payload;
		case PRODUCTS.CHANGE_LOCAL_ITEM:
			return {
				...state,
				items: [...action.payload]
			}
		case PRODUCTS.SET_CHANGED_ITEM:
			return {
				...state,
				items: [...action.payload]
			}
		case PRODUCTS.PAGINATE_PRODUCTS:
			return {
				...state,
				items: [...state?.items, ...action.payload.items]
			}
		default:
			return state;
	}
};

