import React, { useState } from 'react';
import "../../styles/Guest/Guest.scss"
import Config from "Config";
import { useParams, useHistory } from "react-router-dom";
import { showModal } from '../../actions/baseActions';
import ModalTypes from '../../enums/Modal.Types.Enum';
import { useDispatch } from 'react-redux';
const BaseUrl = Config.ServerUrl;
const PageForGuest = () => {
    const dispatch = useDispatch();
    const { token } = useParams();
    const history = useHistory()
    const [Passwords, setPasswords] = useState({
        password: "",
        repeatPassword: ""
    });
    const sendDataForRegister = () => {
        if (Passwords.password && Passwords.password !== Passwords.repeatPassword) {
            return false
        } else {
            let body = {
                password: Passwords.password,
                repeatPassword: Passwords.repeatPassword
            }
            return fetch(BaseUrl + "/api/v1/order/clietn/account/password/" + `${token}`, {
                method: "Post",
                body: JSON.stringify(body).toString()
            }).then(data => {
                if (data.success) {
                    history.push("/")
                    dispatch(showModal(ModalTypes.SIGN_IN_MODAL))
                }
            })
        }
    }
    const onHandleChange = e => {
        setPasswords({
            ...Passwords,
            [e.target.name]: e.target.value
        })
    }
    return (
        <div className='guest_wrapper'>
            <h1>Please enter your  password and register</h1>
            <input placeholder='Password' name='password' value={Passwords.password} onChange={e => onHandleChange(e)} />
            <input placeholder='Repeat Password' name='repeatPassword' value={Passwords.repeatPassword} onChange={e => onHandleChange(e)} />
            <button onClick={sendDataForRegister} >Register</button>
        </div>

    )
}
export default PageForGuest