export const Roles = {
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_USER: "ROLE_USER",
  ROLE_CLIENT: "ROLE_CLIENT",
  ROLE_PARTNER: "ROLE_PARTNER",
  ROLE_PARTNER_MANAGER: "ROLE_PARTNER_MANAGER",
  ROLE_DRIVER: "ROLE_DRIVER",
  ROLE_MODERATOR: "ROLE_MODERATOR"
};

export default Roles;
