import React, { memo } from 'react';
import { connect } from "react-redux";
import {setSelectedProduct, showLocalLoading, showModalRegularMenu} from "../../../actions/baseActions"
import { getSingleProductDetails } from '../../../api/Home.API';
const RestaurantProductItem = memo(({ item,showModalRegularMenu,showLocalLoading,setSelectedProduct }) => {
    const openPopUp = (slug) => {
        showLocalLoading(true)
        getSingleProductDetails(slug).then(({ data }) => {
            setSelectedProduct(data)
            showModalRegularMenu();
            showLocalLoading(false)
        })
    }
    const { downloadLink = "", id, name, description, price,slug } = item
    return (
        <div key={id} className="products_item" onClick={()=>openPopUp(slug)}>
            {!!downloadLink && <div className='product_item_img' style={{ backgroundImage: `url(${downloadLink})` }} ></div>}
            <div className={downloadLink?'products_item_description':'products_item_description without_photo'}>
                <p>{name}</p>
                <h1>{description}</h1>
                <h2>${price}</h2>
            </div>         
        </div>
    )
})


const mapDispatchToProps = {
    showModalRegularMenu,
    showLocalLoading,
    setSelectedProduct
};

export default connect(
    null,
    mapDispatchToProps
)(RestaurantProductItem);