export  const filterList = [
    {
        name: "Higher price",
        value: "HigherPrice"
    },
    {
        name: "Lower price",
        value: "LowerPrice"
    },
    {
        name: "By rating",
        value: "byRating"
    },
    {
        name: "Nearest to me",
        value: "nearestToMe"
    },
    {
        name: "From A to Z",
        value: "fromAtoZ"
    },
    {
        name: "From Z to A",
        value: "fromZtoA"
    },

];