import React, { Component } from 'react';
import {InfoWindow, Marker} from 'google-maps-react'

class CustomMarker extends Component {
	state = {
		showInfoWindow: false
	};
	
	handleMouseOver = e => {
		this.setState({
			showInfoWindow: true
		});
	};
	
	handleMouseExit = e => {
		this.setState({
			showInfoWindow: false
		});
	};
	
	render() {
		const { showInfoWindow } = this.state;
		const props = this.props;
		// const { children } = props;
		return (
			<Marker
				{...props}
				onMouseOver={this.handleMouseOver}
				onMouseOut={this.handleMouseExit}
			>
				{showInfoWindow && (
					<InfoWindow>
						<h1>sdfsdfsdf</h1>
					</InfoWindow>
				)}
			</Marker>
		);
	}
}

export default CustomMarker;
