import * as Http from "../api/Http.Client";
import Config from "Config";
import {getAuthData} from "./User.Service";

var isUpdated = false;

export const updateAccessToken = () => {
    if (isUpdated) return Promise.resolve(true);

    isUpdated = true;

    const authData = getAuthData();
    const formData = new FormData();
    formData.append('refresh_token', authData.refreshToken);
    formData.append('client_id', Config.ClientId);
    formData.append('client_secret', Config.ClientSecret);
    formData.append('grant_type', "refresh_token");

    return Http.Post(
        `api/v1/login`,
        {},
        formData,
        false,
        true
    ).then(result => {
        if (result.data && result.data.access_token) {
            const data = result.data;
            isUpdated = true;

            authData.refreshToken = data.refresh_token;
            authData.token = data.access_token;
            authData.expiresIn = data.expires_in;

            localStorage.setItem("AuthData", JSON.stringify(authData));
            setTimeout(() => {
                isUpdated = false;
            }, 5000);

            return true;
        } else {
            isUpdated = false;
            return false;
        }
    });
};
