import React,{memo} from 'react';

const Header = memo(() => {
    return <div className='regular_header'>
        <span>
            What to eat today
        </span>
    </div>
})

export default Header;