import initialState from "../store/_initialState";
import {REVIEW} from "../actions/_actionTypes";

export const reviewReducer = (state = initialState.reviews, action) => {
	switch (action.type) {
		case REVIEW.SET_REVIEWS:
			return Object.assign({}, state, {
				hasMore: action.payload.length === 10,
				data: [...state.data, ...action.payload]
			});
		case REVIEW.ADD_NEW_REVIEW:
			return Object.assign({}, state, {
				data: [action.payload, ...state.data]
			});
		case REVIEW.CLEAR_REVIEWS:
			return Object.assign({}, state, {
				hasMore: false,
				data: []
			});
		default:
			return state;
	}
};
