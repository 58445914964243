import React from "react";
import { useHistory } from "react-router-dom";
import Paths from "../../services/Paths";
import errorImg from "../../img/404error.png"
import search from "../../img/searchIcon.png"
const NoMatch = () => {
  const history = useHistory()
  return (
    <div className={'pageNotFound'}>
      <h1>Oops!</h1>
      <p>That page can’t be found</p>
      <img src={errorImg} alt="error" />
      <p>Nothing has been found at this location.
        Try searching, or check out the links below.</p>
      <div className="input_wrapper">
        <img src={search} alt="search" />
        <input placeholder="Search..."  />
      </div>
      <button onClick={() => {
        history.push(Paths.home)
      }} >Go home</button>
    </div>
  )

}

export default NoMatch
