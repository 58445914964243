import initialState from "../store/_initialState";
import { FILTER } from "../actions/_actionTypes";

export const filterReducer = (state = initialState.filter, action) => {
	switch (action.type) {
		case FILTER.UPDATE_FILTER:
			return Object.assign({}, state, action.payload);
		case FILTER.RESET_FILTER:

			return Object.assign({}, state, {
				cal: action.payload.cal.max,
				deliveryTime: action.payload.deliveryTime.max,
				priceMin: action.payload.price.min,
				priceMax: action.payload.price.max,
				isFiltered: false,
				isFreeDelivery: undefined,
				isOpenNow: undefined,
				selectedCategories: [],
			});
		case FILTER.UPDATE_DEFAULT_DATA:
			return Object.assign({}, state, {
				cal: action.payload.cal.max,
				deliveryTime: action.payload.deliveryTime.max,
				priceMin: action.payload.price.min,
				priceMax: action.payload.price.max
			});
		default:
			return state;
	}
};

export const filterDefaultDataReducer = (state = initialState.defaultFilterData, action) => {
	if (action.type === FILTER.UPDATE_DEFAULT_DATA) {
		return Object.assign({}, state, action.payload);
	}
	return state;
};
export const selectedRestaurantFilterReducer = (state = initialState.selectedRestaurantFilter, action) => {
	switch (action.type) {
		case FILTER.SELECTED_RESTAURANT_FILTER:
			return action.payload;
		default:
			return state;
	}

}
export const filterModalReducer = (state = initialState.filterModal, action) => {
	switch (action.type) {
		case FILTER.SHOW_FILTER:
			return action.payload===false ? action.payload:!state;
		default:
			return state;
	}

}