import initialState from "../store/_initialState";
import { BASKET } from "../actions/_actionTypes";
import * as BasketService from '../services/Basket.Service';
import { getBasket, removeBasket } from "../api/Basket.API";

export const basketReducer = (state = initialState.basket, action) => {
	switch (action.type) {
		case BASKET.SET_ITEMS:
			return BasketService.calculateBasket({
				...state,
				products: action.products,
				restaurant: action.restaurant,
				isCatering: state.isCatering
			});
		case BASKET.ADD_FIRST_ITEM:
			return {
				...state,
				...action.data
			};
		case BASKET.REMOVE_LOCAL_ITEM:
			return {
				...state,
				items: [...action.payload]
			};
		case BASKET.ADD_NEW_PRODUCT:
			return BasketService.calculateBasket({
				...state,
				restaurant: {
					...state.restaurant,
				},
				products: {
					...state.products,
					[action.product.uid]: action.product
				}
			})
		case BASKET.UPDATE_ITEM:
			if ('restaurant' in action && 'slug' in action.restaurant) {
				return BasketService.calculateBasket({
					...state,
					restaurant: {
						...state.restaurant,
					},
					products: {
						...state.products,
						[action.item.uid]: action.item
					}
				})
			}
			return state;
		case BASKET.REMOVE_ITEM:
			let newBasket
			removeBasket(action.productKey).then(getBasket(data => newBasket = data));
			return {
				state: { ...newBasket }
			}
		case BASKET.CHANGE_COUNT:
			const { productKey, count } = action.payload;

			if (count > 0) {
				if (productKey in state.products) {
					state.products[productKey].counts = count;

					return BasketService.calculateBasket({
						...state,
						restaurant: state.restaurant
					})
				}
			}
			return state;
		case BASKET.CALCULATE_BASKET:
			return BasketService.calculateBasket(state);
		case BASKET.CLEAR_CART:
			BasketService.clearBasket();
			return {
				restaurant: {},
				subtotal: 0,
				delivery: 0,
				productCount: 0
			};
		default:
			return state;
	}
};
