import React from "react";
import {connect} from "react-redux";
import "../../styles/ShoppingCart/ShoppingCart.scss";
import ShoppingCart from "./ShoppingCart";
import ShoppingLocation from "./ShoppingLocation";
import ShoppingPayment from "./ShoppingPayment";
import OrderedPage from "./OrderedPage";
import ErrorPage from "./ErrorPage";
import {showModal} from "../../actions/baseActions";

const ShippingCart = ({step}) => {

    const viewComponent = () => {
        switch (step) {
            case 1:
                return <ShoppingCart/>;

            case 2:
                return <ShoppingLocation/>;

            case 3:
                return <ShoppingPayment/>;

            case 4:
                return <OrderedPage/>;
            case 5:
                return <ErrorPage/>;
            default:
                break;
        }
    };
    return (
        <>
            {viewComponent()}
        </>
    )
};
const mapStateToProps = (state) => ({
    step: state?.checkout?.checkoutStep,
    isDelivery: state.isDelivery,
});

const mapDispatchToProps = {
    showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShippingCart);
