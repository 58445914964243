import {ORDER_DELIVERY_TYPE, ORDER_TYPE} from "../actions/_actionTypes";
import initialState from "../store/_initialState";
import {OrderDeliveryType, OrderType} from "../enums/Order.Status.Enum";

export const orderDeliveryTypeReducer = (state = initialState.isDelivery, action) => {
	if (action.type === ORDER_DELIVERY_TYPE.UPDATE_ORDER_DELIVERY_TYPE) {
		const isDelivery = action.payload === OrderDeliveryType.IS_DELIVERY;
		sessionStorage.setItem('isDelivery', isDelivery);
		return isDelivery;
	}
	return state;
};

export const orderTypeReducer = (state = initialState.isCatering, action) => {
	if (action.type === ORDER_TYPE.UPDATE_ORDER_TYPE) {
		return action.payload === OrderType.IS_CATERING;
	}
	return state;
};
