import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";

import '../../styles/HomeStyles/home.scss'


import CateringFindFood from '../../container/CateringFindFood/CateringFindFood';
import Custumers from "../../container/Custumers/Custumers";
import DownloadApp from './../../container/DownloadApp/DownloadApp';
import CateringBusiness from '../../container/CateringBusiness/CateringBusiness';

class HomeComponent extends Component {
	state = { dragging: false };

	//TODO Slider change
	handleSliderBeforeChange = () => {
		this.setState({ dragging: true })
	};

	handleSliderAfterChange = () => {
		this.setState({ dragging: false })
	};

	render() {
		const {
			currentLocation,
			isAddressSet,
		} = this.props;
		return (
			<>
				<CateringFindFood
					title={isAddressSet && currentLocation.address ? 'One hour of happiness' : 'Fastest' + <span>Delivery</span> + '& Easy' + <span>Pickup</span>}
					onAddressConfirmed={this.onAddressConfirmed} />
				<Custumers />
				<DownloadApp />
				<CateringBusiness />
			</>
		);
	}
}

HomeComponent.propTypes = {
	currentLocation: PropTypes.object.isRequired,
	isAuth: PropTypes.bool.isRequired,
	isAddressSet: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
	currentLocation: state.currentLocation.address,
	isAddressSet: state.currentLocation.isAddressSet,
	isAuth: state.user.isAuth,
});



export default connect(
	mapStateToProps,
	null,
)(HomeComponent);

