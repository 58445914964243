import initialState from "../store/_initialState";
import { PAGE } from "../actions/_actionTypes";

export const pageCountReducer = (state = initialState.pageCount, action) => {
    switch (action.type) {
        case PAGE.ADD_PAGE_COUNT:
            return state + action.payload
        case PAGE.ADD_PAGE_COUNT_ONE:
            return 1
        default:
            return state;
    }
};